import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import 'mapbox-gl/dist/mapbox-gl.css';
import IconButton from "@material-ui/core/IconButton";
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import AppContext from '../../Services/AppContext';
import {Box, Button, Chip, CircularProgress, DialogContent, Grid, Paper} from '@material-ui/core';
import {req_category_cust, req_cust_category_skustandard, req_cust_list, req_stock_def} from '../../Helper/Constants';
import MUIDataTable from 'mui-datatables';
import VirtualListCB from '../VirtualListCB';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from "@material-ui/core/Tooltip";
import _ from 'lodash';
import EditCustCategory from '../EditCustCategory';
import moment from 'moment';
import { de } from '../../Helper/Helper';

const useStyles = makeStyles((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	appBar: {
		position: 'relative',
	},
	root: {
		flexGrow: 1
	},
	grid: {
		marginTop: 10,
		paddingLeft: 20,
		paddingRight: 20
	},
	dlgContent: {margin: -20, marginTop: -10},
	paper: {
		padding: 10
	},
	paperMaxHeight: {
		padding: 10,
		maxHeight: 120
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
	{
		name: "product_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "product_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "updated_at",
		label: "Updated At",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
				)
			}
		}
	}
];

export default function CustCategoryDetails({
											item: currentItem,
											open,
											setClose,
											language,
											onUpdateCustCategory,
											onSnack,
											integration,
											mobileCustomization,
											modules
										}) {
	const [gState] = useContext(AppContext);
	const classes = useStyles();
	const [axiosConfig, setAxiosConfig] = useState({});
	const [tabPosition, setTabPosition] = useState(0);
	const [item, setItem] = useState(currentItem);
	const [showDropzone, toggleDropZone] = useState(true);
	const [openCustCategoryEdit, setOpenCustCategoryEdit] = useState(false);
	const [categoryImage, setCategoryImage] = useState(null);
	const [custCategoryData, setCustCategoryData] = useState([]);
	const [newItemListOpen, setNewItemListOpen] = useState(false);
	const [newItemList, setNewItemList] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [showItemList, setShowItemList] = useState(false);
	const [standardItemList, setStandardItemList] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			setAxiosConfig(axiosGetConfig);
		}
	}, [gState?.client]);

	useEffect(() => {
		if (!_.isEmpty(currentItem) && !_.isEmpty(axiosConfig)) {
			loadCustCategoryList();
			loadNewCustomerList();
			if (modules.hasOwnProperty('skustandard') && modules.skustandard.enabled) {
				// loadItemList();
				loadSKUItemList();
			}
		}
	}, [currentItem, axiosConfig]);

	// useEffect(() => {
	// 	loadSKUItemList();
	// }, [itemList]);

	const onClose = () => {

		setClose();
	}

	const editCustCategory = (data) => {
		setOpenCustCategoryEdit(false);
		if (data) {
			setItem(data[0]);
			onUpdateCustCategory();
		}
	}

	const loadCustCategoryList = (config = axiosConfig) => {
		API_Service.get(`${req_cust_list}?cust_category_id=${item.cust_category_id}`, _.isEmpty(config) ? axiosConfig : config).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				onSnack(language.no_data, 'warning');
			} else {
				setCustCategoryData(() => (extraData));
			}
		});
	}

	const loadNewCustomerList = (config = axiosConfig) => {
		API_Service.get(req_cust_list, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _arr = [];
				for (let i = 0; i < extraData.length; i++) {
					const {cust_code, cust_company_name, customer_status, cust_category} = extraData[i];
					if (customer_status === '1' && cust_category === '0') {
						_arr.push({
							name: cust_company_name,
							secondary: cust_code,
							value: cust_code,
							status: parseInt(customer_status)
						});
					}else{
						_arr.push({
							name: cust_company_name,
							secondary: cust_code,
							value: cust_code,
							hide: true,
							status: parseInt(customer_status)
						});
					}
				}

				if (_arr.length > 0) {
					setNewItemList(newItemList => (_arr));
				} else {
					onSnack(language.no_data, 'warning');
				}
			}
		});
	}

	const loadItemList = (config = axiosConfig) => {
		return API_Service.get(req_stock_def, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				const {stock} = extraData;
				var _arr = [];
				for (let i = 0; i < stock.length; i++) {
					const {product_code, product_name, product_desc, product_status} = stock[i];
					if (product_status === '1') {
						_arr.push({
							name: product_name,
							secondary: product_code,
							value: product_code,
							status: parseInt(product_status)
						});
					}
				}
				return _arr;
			}
		});
	}

	const loadSKUItemList = (config = axiosConfig) => {
		setItemList([]);
		setStandardItemList([]);
		loadItemList().then((latestItemList) => {
			API_Service.get(`${req_cust_category_skustandard}?cust_category_id=${item.cust_category_id}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					for (let i = 0; i < extraData.length; i++) {
						const {product_code} = extraData[i];
						for (let x = 0; x < latestItemList.length; x++) {
							const {value} = latestItemList[x];
							if (value === product_code) {
								latestItemList[x]['hide'] = true;
							}
						}
					}
					setStandardItemList(extraData);
				}
				setItemList(latestItemList);
			});
		});
	}

	const custCategoryViewClose = (newValue) => {
		setNewItemListOpen(false);
		if (newValue) {
			if (newValue['name'] != 'reset') {
				var formdata = new FormData();
				formdata.append('cust_category_id', item.cust_category_id);
				formdata.append('customer', JSON.stringify(newValue));

				API_Service.post(req_category_cust, formdata, axiosConfig).then(({data}) => {
					var {result, extraData} = data;
					result = JString(result).toNumber();
					if (result === 1) {

						setCustCategoryData(() => (extraData ? extraData : []));
						loadNewCustomerList();
						onSnack && onSnack(language.record_updated, 'success');
					} else {
						onSnack && onSnack(language.operation_fail, 'error');
					}
				});
			}
		} else {

		}
	}

	const onShowItemList = () => {
		setShowItemList(true);
	}

	const onCloseItemList = (data) => {
		setLoading(true);
		setShowItemList(false);
		if (data && data['name'] !== 'reset') {
			if (data.length > 0) {
				var formdata = new FormData();
				formdata.append('cust_category_id', item.cust_category_id);
				formdata.append('product', JSON.stringify(data));

				API_Service.post(req_cust_category_skustandard, formdata, axiosConfig).then(({data}) => {
					var {result, extraData} = data;
					result = JString(result).toNumber();
					if (result === 1) {
						loadSKUItemList();
						onSnack && onSnack(language.record_updated, 'success');
						setLoading(false);
					} else {
						onSnack && onSnack(language.operation_fail, 'error');
					}
				});
			} else { setLoading(false); }
		} else { setLoading(false); }
	}

	const onRowDelete = (row) => {
		setLoading(true);
		const _arr = [];
		for (let i = 0; i < row.length; i++) {
			var {dataIndex} = row[i];
			_arr.push(standardItemList[dataIndex]['product_code']);
		}

		var axiosPut = axiosPutConfig(gState.client);
		var params = axiosURIencode({
			products: _arr.join(","),
			cust_category_id: item.cust_category_id
		});

		API_Service.put(req_cust_category_skustandard, params, axiosPut).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				loadSKUItemList();
				setLoading(false);
				onSnack && onSnack(language.record_updated, 'success');
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
		});
	}

	return (
		<div className={classes.root}>
			<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{language.cust_category_details}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent className={classes.dlgContent}>
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={4}>
							<Paper className={classes.paper}>
								<Grid container spacing={2}>
									<Grid item xs={12} lg direction="column" container>
										<Typography variant="subtitle1" className={classes.textleft}>
											{item.cust_category_code}
										</Typography>
										<Typography variant="inherit" component="h4" className={classes.textleft}>
											{item.cust_category_name}
										</Typography>
									</Grid>
									{
										integration === '0' && (
											<Button
												onClick={() => setOpenCustCategoryEdit(true)}
												size="small">
												<EditIcon/>
											</Button>
										)
									}
								</Grid>
							</Paper>
							{
								modules.hasOwnProperty('skustandard') && modules.skustandard.enabled ? (
									<>
										<Space/>
										<Paper className={classes.paper}>
											<Grid container spacing={2}>
												<Grid item xs={12} lg direction="column" container>
													<Button onClick={() => onShowItemList()} variant={'outlined'} disabled={loading} color={'primary'} fullWidth>
														<Typography>{modules.skustandard.title} </Typography>
														{loading && (<CircularProgress color="inherit" size={15}/>)}
													</Button>
													<Box display="flex" justifyContent="flex-end">
														{standardItemList.length} SKU
													</Box>
												</Grid>
											</Grid>
											{
												standardItemList.length > 0 && (
													<Grid container spacing={2}>
														<MUIDataTable
															data={standardItemList}
															columns={columns}
															options={{
																pagination: false,
																print: false,
																filter: false,
																responsive: 'standard',
																download: false,
																viewColumns: false,
																search: false,
																dense: true,
																selectableRows: standardItemList.length > 0 ? 'multiple' : 'none',
																onRowsDelete: ({data: row}) => onRowDelete(row),
																setTableProps: () => ({size: 'small'}),
															}}
														/>
													</Grid>
												)
											}
										</Paper>
									</>
								) : <></>
							}
						</Grid>
						<Grid item xs={8}>
							<CustCategoryItemList
								data={custCategoryData}
								language={language}
								gState={gState}
								onEvent={() => loadCustCategoryList()}
								onSnack={onSnack}
								setNewItemListOpen={setNewItemListOpen}
								item={item}
							/>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<EditCustCategory
				open={openCustCategoryEdit}
				onClose={(data) => editCustCategory(data)}
				language={language}
				cust_category_code={item.cust_category_code}
				cust_category_name={item.cust_category_name}
				marker_color={item.marker_color}
				category_udf={item.category_udf}
				mobileCustomization={mobileCustomization}
			/>
			{
				newItemListOpen && (
					<VirtualListCB
						setClose={(d) => custCategoryViewClose(d)}
						open={newItemListOpen}
						title={'New Customer'}
						data={newItemList}
						language={language}
						filteration={0}
					/>
				)
			}
			{
				showItemList && (
					<VirtualListCB
						setClose={(d) => onCloseItemList(d)}
						open={showItemList}
						title={'Product List'}
						data={itemList}
						language={language}
						filteration={0}
					/>
				)
			}
		</div>
	)
}

function Space({height = 10}) {
	return (
		<div style={{height}}/>
	)
}

function CustCategoryItemList({data, language, gState, onEvent, onSnack, setNewItemListOpen, item}) {
	const onRowDelete = (row) => {
		const _arr = [];
		for (let i = 0; i < row.length; i++) {
			var {dataIndex} = row[i];
			_arr.push(data[dataIndex]['cust_code']);
		}
		var axiosPut = axiosPutConfig(gState.client);
		var params = axiosURIencode({
			customer: _arr.join(","),
			ori_code: item.cust_category_id
		});

		API_Service.put(req_category_cust, params, axiosPut).then(({data}) => {
			var {result} = data;
			result = JString(result).toNumber();
			if (parseInt(result) === 1) {
				onSnack(language.record_updated, 'success');
			} else {
				onSnack(language.operation_fail, 'error');
			}
			onEvent();
		});
	}
	return (
		<MUIDataTable
			title={language.cust_list}
			data={data || []}
			columns={itemDataCell}
			options={{
				print: false,
				filter: false,
				rowsPerPage: 99,
				rowsPerPageOptions: [125, 140, 165, 200],
				responsive: 'simple',
				download: false,
				viewColumns: false,
				selectableRows: data.length > 0 ? 'multiple' : 'none',
				onRowsDelete: ({data: row}) => onRowDelete(row),
				setTableProps: () => ({size: 'small'}),
				customToolbar: () =>
					<TemplateTableCustomToolbar
						addCateogryCust={() => {
							setNewItemListOpen(true)
						}}
					/>
			}}
		/>
	)
}

const itemDataCell = [
	{
		name: "cust_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "created_date",
		label: "Created Date",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "updated_at",
		label: "Last Updated",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
				)
			}
		}
	},
	{
		name: "customer_status",
		label: "Status",
		options: {
			display: true,
			customBodyRender: (value) => {
				switch (value) {
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "0" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>);
						break;
					default :
						return (null);
						break;
				}
			}
		}
	}
];

function TemplateTableCustomToolbar({addCateogryCust}) {
	return (
		<React.Fragment>
			<Tooltip title={'Add Into Category'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => addCateogryCust()} aria-label="addnew">
					<AddIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
