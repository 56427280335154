const translations = {
  "en":{
    "dashboard":"Dashboard",
    "transaction":"Transactions",
    "monthly_sales":"Monthly Sales",
    "semiannual_sales":"Semi Annual Sales",
    "logout":"Logout",
    "top_salesman":"TOP SALESMAN",
    "top_customer":"TOP CUSTOMER",
    "sales":"SALES",
    "outstanding":"OUTSTANDING",
    "top_items":"Top Selling Items",
    "total":"TOTAL",
    "es_backend":"EASYM",
    "proceed_logout":"You are proceeding to logout. By logging out, the system will clear cache data which includes theme, language, chosen search criteria and so on. You can set them back upon your next login.",
    "logout_cap":"LOGOUT",
    "cancel":"CANCEL",
    "search_anything":"Search Anything",
    "search":"Search...",
    "search_cap":"SEARCH",
    "clear":"CLEAR",
    "clear_all":"CLEAR ALL",
    "delete":"Delete",
    "selected":"selected",
    "settings":"Settings",
    "save":"SAVE",
    "rows_per_page":"Rows per page",
    "table_info":"Transaction Table Info",
    "search_crit":"Search Criteria",
    "today":"Today",
    "yesterday":"Yesterday",
    "last_week":"Last 1 Week",
    "last_month":"Last 1 Month",
    "choose_date":"Choose Date",
    "doc_date":"Document Date",
    "del_date":"Delivery Date",
    "ok":"OKAY",
    "active_doc":"Active Documents",
    "inactive_doc":"Inactive Documents",
    "pending_doc":"Pending Documents",
    "salesman":"AGENT",
    "customer":"Outlet",
    "cust_list":"Outlet List",
    "no_internet":"You are not connected to internet",
    "doc_details":"Doc Details",
    "active_doc_done":"In-App documents loaded",
    "confirm_doc_done":"Confirmed documents loaded",
    "trans_doc_done":"Transferred documents loaded",
    "posted_doc_done":"Posted documents loaded",
    "doc_dtl_fail":"Document details loading failed",
    "inapp_doc":"In-App Documents",
    "confirmed_doc":"Confirmed Documents",
    "trans_doc":"Transferred Documents",
    "posted_doc":"Posted Documents",
    "reset_selection":"RESET",
    "no_doc_dtl":"No details found",
    "user_accounts":"User Accounts",
    "no_inapp_doc":"No In-App Documents Found",
    "no_confirmed_doc":"No Confirmed Documents Found",
    "no_trans_doc":"No Transferred Documents Found",
    "no_posted_doc":"No Posted Documents Found",
    "create_selforder":"Create SelfOrder Login",
    "users":"Users",
    "create_user":"Create User",
    "user_saved":"User is created",
    "user_exists":"Username already exists",
    "operation_fail":"Operation Failed",
    "operation_success":"Operation Successful",
    "edit_user":"Edit User",
    "username":"username",
    "password":"Password",
    "old_pw":"old password",
    "new_pw":"new password",
    "notify_email":"Notify user via email",
    "user_email":"user email",
    "amend":"Amend",
    "load_ua":"Loading user accounts",
    "load_ua_failed":"Seems like there is no user in record",
    "username_error":"Username must be more than 2 characters",
    "password_error":"Password must be more than 4 characters",
    "unsafe":"Password must not contain / [ < ( ^ * | ., : ; ) > ]",
    "unsafe_username":"Username contain any special character",
    "require_cust":"You must choose a outlet",
    "chng_pw_title":"Change Password",
    "update":"UPDATE",
    "old_pw_nomatch":"Old password does not match with the username",
    "no_user_update":"Old password & new password is same",
    "user_updated":"User password is updated",
    "invalid_email":"Invalid email address",
    "user_locked":"User is locked",
    "user_unlocked":"User is unlocked",
    "reconciliation":"Reconciliation",
    "date_from":"Date From",
    "date_to":"Date To",
    "date":"Date",
    "checked":"Checked",
    "pending":"PENDING",
    "missed":"MISSED",
    "attended":"ATTENDED",
    "reject":"Reject",
    "status":"Status",
    "no_data":"No data found",
    "invoice_list":"Invoice List",
    "debit_list":"Debit List",
    "credit_list":"Credit Note List",
    "receipt_list":"Receipt List",
    "stock":"Stock List",
    "category":"Category",
    "items":"Items",
    "show_items":"Show Items",
    "show_details":"Show Details",
    "search_result":"Search Result",
    "item_dtl":"Item Details",
    "set_virtual_qty":"Set Virtual QTY (Ex: 10%)",
    "current_qty":"Current QTY",
    "avbl_qty":"Available QTY",
    "virtual_qty":"Virtual QTY",
    "customer_low":"Outlet",
    "record_updated":"Data update successful",
    "cust_dtl":"Outlet Details",
    "branches":"Branches",
    "choose_vip_item":"Choose Item For VIP QTY",
    "vip_items":"Items with VIP QTY",
    "select_item":"Please select an item",
    "app_content":"App Content",
    "upload_adver":"App Advertisement",
    "adver_name":"Advertisement name",
    "add_adver":"Upload a new advertisement",
    "adver_success":"Advertisement uploaded",
    "adver_fail":"Advertisement uploading failed",
    "advername_exists":"Please use another advertisement name",
    "listview":"List View",
    "gridview":"Grid View",
    "set_default":"SET DEFAULT",
    "default":"DEFAULT",
    "def_del_image":"Cannot delete default image",
    "vip_qty":"VIP QTY",
    "edit_vip":"Edit VIP QTY",
    "vip_qty_list":"VIP QTY List",
    "comment":"Comment",
    "attachments":"Attachments",
    "signed_statements":"Signed Statements",
    "event_items":"Event Items",
    "create_event":"Create Event",
    "events":"Events",
    "search_events":"Search event",
    "event_name":"Event name",
    "event_desc":"Event description",
    "event_name_inv":"Invalid event name",
    "close":"Close",
    "event_same_date":"You are going to save this event with same start and end date",
    "proceed_caps":"PROCEED",
    "newevent_created":"New event is created",
    "event_updated":"Event details is updated",
    "no_events":"No events found",
    "active":"Active",
    "inactive":"Inactive",
    "event_delete":"You are going to delete this event. All the items and rules under this event will be deleted. Do you want to proceed?",
    "search_by_month":"Search by month",
    "create_detail":"Create Segment",
    "dtl_name":"Segment name",
    "dtl_desc":"Segment details",
    "dtl_remark":"Segment remark",
    "min_qty":"Min QTY",
    "min_order_qty":"Min Order QTY",
    "edit_min_qty":"Edit Min QTY",
    "min_qty_list":"Min Order QTY List",
    "min_qty_updated":"Min QTY updated!",
    "min_qty_updated_product":"Product Min QTY updated!",
    "min_qty_failed_update":"Min QTY update failed!",
    "min_qty_deleted":"Min QTY deleted!",
    "max_qty":"Max QTY",
    "item_qty":"Item QTY",
    "min_purchase":"Min Item Purchase",
    "max_get":"Max Item Get",
    "min_price":"Min Price",
    "max_price":"Max Price",
    "adver_popup":"Advertisement Update Popup",
    "adver_old_name":"current name",
    "adver_new_name":"new name",
    "adver_image":"image",
    "chng_adver_name_title":"Change Advertisement Name",
    "adver_name_updated":"Advertisement name is updated",
    "adver_deleted":"Advertisement has been deleted",
    "no_adver_updated":"No advertisement updated",
    "active_adver":"Active",
    "inactive_adver":"Inactive",
    "show_active_adver":"Showing Active Advertisement",
    "show_inactive_adver":"Showing Inactive Advertisement",
    "failed_retrieve_adver":"Failed to retrieve advertisement",
    "add_announcement":"Upload a new announcement",
    "announcement_success":"Announcement uploaded",
    "announcement_fail":"Announcement uploading failed",
    "announcement_exists":"Please use another announcement name",
    "announcement_name":"Announcement name",
    "announcement_desc":"Announcement description",
    "announcement_same_date":"Announcement date cannot be in same date",
    "app_announcement":"App Announcement",
    "announcement_image":"Image",
    "announcement_current_name":"Current Title",
    "announcement_new_name":"New Title",
    "announcement_current_desc":"Current Description",
    "announcement_new_desc":"New Description",
    "announcement_current_datefrom":"Current Date From",
    "announcement_new_datefrom":"New Date From",
    "announcement_current_dateto":"Current Date To",
    "announcement_new_dateto":"New Date To",
    "announcement_update_dtl":"Update Announcement Details",
    "announcement_dtl":"Announcement Details",
    "announcement_info_updated":"Announcement details is updated",
    "announcement_info_not_updated":"No announcement updated",
    "announcement_success_deactivated":"Announcement successfully deactivated",
    "announcement_success_activated":"Announcement successfully activated",
    "announcement_failed_deactivated":"Failed to deactivate announcement",
    "announcement_failed_activated":"Failed to activate announcement",
    "show_active_announcements":"Showing Active Announcements",
    "show_inactive_announcements":"Showing Inactive Announcements",
    "failed_retrieve_announcements":"Failed to retrieve Announcements",
    "disable_editing_announcements":"Inactive announcements are disable for any changes",
    "activate_btn":"ACTIVATE",
    "search_item":"Search Item",
    "custom_price":"Custom Price",
    "add":"Add",
    "choose_price":"Choose Price",
    "foc":"FOC",
    "item_price":"Price",
    "sameitem_foc":"Same Item FOC",
    "lowest_foc":"Lowest Price FOC",
    "choose_states":"Choose State",
    "postage_fee":"Postage Fee",
    "payment":"Payment",
    "upload":"Upload",
    "category_img":"Upload Category Image",
    "remove_cat_image":"Remove Category Image",
    "upload_success":"Upload Successful",
    "upload_fail":"Upload Fail",
    "deleted":"Deleted!!!",
    "delete_fail":"Delete Failed",
    "available_qty":"Available QTY",
    "price":"Price",
    "qty":"Quantity",
    "edit_rec":"Edit Record",
    "agent_list":"Agent List",
    "checkin":"Check-In",
    "checkin_dtl":"Check-In Details",
    "checkin_loaded":"Check-In data found",
    "adver_status":"Advertisement status changed",
    "show_all":"Show All",
    "enter_item_name":"Enter item name",
    "create_newsubcat":"Create new segment category",
    "subcat_list":"Segment category list",
    "new_subcat":"New segment category",
    "checkin_sched":"Check-In Schedule",
    "search_agent":"Search agent",
    "appointment_time":"Appointment at",
    "note_agent":"Note for agent",
    "sel_agent":"Please select an agent",
    "schedules":"Schedules",
    "generate":"Generate",
    "picking_list":"PICKING LIST",
    "delivery_info":"Delivery Info",
    "expected_del_date":"Expected delivery date",
    "stock_transfer":"Stock Transfer",
    "stock_take":"Stock Take",
    "stock_take_list":"Stock Take List",
    "stock_take_details":"Stock Take Details",
    "backend_sync":"Backend Sync",
    "stock_template":"Stock Template",
    "chg_new_cust":"Change to new outlet",
    "chg_new_cust_cnfrm":"Are you sure you want to change to new outlet?",
    "update_doc_dtl":"Update Doc Details",
    "doc_info":"Doc Information",
    "cust_tel":"Outlet Tel No",
    "deli_add_":"Delivery Address",
    "deli_add_1":"Delivery Address 1",
    "deli_add_2":"Delivery Address 2",
    "deli_add_3":"Delivery Address 3",
    "deli_add_4":"Delivery Address 4",
    "city":"City",
    "state":"State",
    "zipcode":"Zip Code",
    "country":"Country",
    "view":"VIEW",
    "search_item_type":"Search item type",
    "item_not_exist":"ITEM DOES NOT EXIST IN RECORD",
    "create_type":"Create item type",
    "add_item":"Add new item",
    "knockoff_doc":"Knock Off Documents",
    "no_knockoff_doc":"No Knock Off document for this payment",
    "active_doc_loaded":"Active Documents loaded",
    "inactive_doc_loaded":"Inactive Documents loaded",
    "module_linkage":"Module Linkage",
    "search_sku_item":"Search SKU items",
    "b2b_client":"B2B Client",
    "b2b_client_list":"B2B Client List",
    "b2b_client_capital":"B2BCLIENT",
    "unlink_client":"UNLINK",
    "link_client":"LINK",
    "swap":"SWAP",
    "b2b_item_list":"B2B Item List",
    "search_b2b_item":"Search B2B items",
    "unlink_item":"UNLINK ITEM",
    "search_customer":"Search Outlet",
    "open_item":"Link a product",
    "room_name":"Room name",
    "room_desc":"Room description",
    "room_caseid":"Case ID",
    "open_room":"Open room",
    "sku_item_list":"SKU Item List",
    "in_range":"In Range",
    "date_range":"Pick a date range",
    "change_status":"Make it active/inactive",
    "stock_template_items":"Stock Template Items",
    "create_new":"Create New",
    "search_stock_template":"Search stock template",
    "stock_template_name":"Stock template name",
    "newstock_template_created":"New stock template is created",
    "stock_template_updated":"Stock template details is updated",
    "stock_template_deleted":"Stock template deleted",
    "no_stock_template":"No stock template found",
    "add_stock_template_item":"Add Stock Template Items",
    "stock_template_item":"Stock Template Items",
    "individual_item":"Individual Items",
    "item_template_pkg":"Item Template/Package",
    "last_5_inv":"Last 5 Invoices",
    "item_selection":"Item Selection",
    "select_cat_here":"Select category here..",
    "select_item_here":"Select item(s) here..",
    "select_package_here":"Select package here..",
    "select_inv_item_here":"Select invoices item(s) here..",
    "remove":"REMOVE",
    "stock_template_items_added":"Stock template item(s) added",
    "inv_items":"Invoice Items",
    "package":"Package",
    "current_items":"CURRENT ITEM(S)",
    "preview_video":"Preview your video here",
    "video":"Video",
    "paste_link_here":"Paste YouTube link here..",
    "pdf":"PDF",
    "pdf_list":"PDF List",
    "preview":"Preview",
    "image":"Image",
    "pdf_uploaded":"PDF file succesfully uploaded!",
    "pdf_failed":"Failed to upload PDF file",
    "video_uploaded":"Video successfully uploaded!",
    "video_failed":"Failed to upload video",
    "not_youtube_link":"This is not YouTube link, kindly insert/paste the correct link",
    "atch_delete":"You are going to delete this attachment. Do you want to proceed?",
    "no_active_document":"No Active Document",
    "person_in_charge":"Person In Charge",
    "set_due_date":"Set Due Date",
    "manage_sched":"MANAGE SCHEDULE",
    "pending_sched":"PENDING SCHEDULE",
    "time":"TIME",
    "duration":"DURATION",
    "set_time":"SPECIFIC TIME",
    "set_duration":"SPECIFIC DURATION",
    "instructions":"Any instructions/note?",
    "repeat_sched":"REPEAT SCHEDULE",
    "single_customer":"SINGLE OUTLET",
    "multiple_customer":"MULTIPLE OUTLET",
    "customer_by_area":"OUTLET BY AREA",
    "fulltext_cust":"Search by address/area/state/country",
    "pic_list":"Person In Charge List",
    "delete_recur":"Delete recurring events",
    "delete_current":"Delete current event",
    "edit_recur":"Edit recurring events",
    "edit_current":"Edit current event",
    "recur_msg":"Schedule appears in a recurring event. You can choose to make change for current event or all the same events",
    "target":"Stock Target",
    "insert_success":"Insert Success",
    "insert_fail":"Insert Failed",
    "not_found_target":"Cannot Found Target",
    "not_found_category":"Cannot Found Category",
    "not_found_item":"Cannot Found Item",
    "not_found_salesperson":"Cannot Found Agent",
    "not_found_item_target":"Cannot Found Item Target",
    "amount_empty":"Amount is empty",
    "salesperson":"Agent",
    "edit_amount":"Edit Amount",
    "date_range_check":"Please select available date, minimum 1 week",
    "target_empty":"Target name is empty",
    "insert_item":"Please insert the item",
    "insert_tag":"Please insert the tag",
    "confirm_update_request":"You want update this target details or generate as a new target? (New target will include all details in this target)",
    "submit":"Submit",
    "add_new_tg_item":"Add New Target Of Item",
    "add_new_tg_tag":"Add New Target Of Tag",
    "add_category_to_tag":"Add Category Of Tag",
    "add_new_tag":"Add New Tag",
    "confirm_update_tg":"Confirm update the target",
    "target_list":"Target List",
    "edit_tag":"Edit Tag",
    "yes":"YES",
    "no":"NO",
    "category_empty":"Category is empty",
    "confirm_insert_details":"Confirm insert this tag",
    "confirm_insert_all_details":"You want duplicate all the details in this tag?",
    "no_attr_selected":"No selected any attributes",
    "empty_tag":"Tag is empty",
    "edit":"Edit",
    "duplicate":"Duplicate",
    "edit_target":"Edit Target",
    "new_target":"Add New Target",
    "tag":"Tag",
    "attribute":"Attribute",
    "generate_commission_report":"Generate Commission Report",
    "report":"Report",
    "nothing_save":"Nothing to save",
    "template_list":"Template List",
    "new_tmplt":"Create New Template",
    "delete_tmplt":"You are about to delete a template",
    "done":"DONE",
    "site_loc":"Site Location",
    "last_visit":"Last Visit",
    "branch":"Branch",
    "note":"Note",
    "phone_number":"Phone Number",
    "search_location":"Search Location",
    "contact_person":"Contact Person",
    "choose_project":"Projects",
    "avg_price_report":"Generate Average Price Report",
    "create_cust_grp":"Create customer group",
    "grp_of":"Group Of",
    "grp_members":"Group Members",
    "b2border":"B2B Order",
    "cash_rolling":"Cash Rolling",
    "cash_rolling_list":"Cash Rolling List",
    "amount":"Amount",
    "new_cash_rolling":"New Cash Rolling",
    "remark":"Remark",
    "cash_rolling_report":"Generate Cash Rolling Report",
    "item_link_success":"B2B item successfully linked!",
    "item_link_failed":"Failed to link B2B item!",
    "remove_customer_success":"B2B client successfully removed from the customer",
    "assign_customer_success":"B2B client successfully assigned to the customer",
    "assign_customer_failed":"Failed to assign B2B client to the customer",
    "sequence_update_success":"Sequence No. update success",
    "sequence_update_failed":"Sequence No. update failed",
    "driver_routing_report":"Generate Driver Routing Report",
    "must_push_noti":"You must enable push notification first",
    "no_modulename_found":"No module name found",
    "salesperson_list":"Agents",
    "bundle":"Bundle",
    "pwp":"PWP",
    "product":"Product",
    "new_bundle":"Add New Bundle",
    "foc_item":"FOC Items",
    "cheaper_item":"Cheaper Items",
    "out_of":"Out Of",
    "cheaper_price":"CHEAPER PRICE",
    "reportgenerator":"Report Generator",
    "transfer_doc_type_title":"Document Type when Transfer to Acc. System",
    "uom_price":"UOM Price",
    "special_price":"Special Price",
    "delivery_report":"Generate Delivery Report",
    "stock_adjustment":"Stock Adjustment",
    "dob":"Delivery On Behalf",
    "stock_transaction":"Stock Transactions",
    "voucher_report":"Voucher Redeemed Report",
    "order_report":"Order Report",
    "region":"Region",
    "stock_clearance":"Stock Clearance",
    "stock_clearance_list":"Stock Clearance List",
    "summary_report":"Summary Report",
    "doc_type":"Doc Type",
    "type":"Type",
    "teras_dashboard":"Teras Dashboard",
    "agent_attend":"Agent Attendance",
    "outlet_visit":"Outlet Visited",
    "out_of_stock":"Out of Stock",
    "total_commission":"Total Commission",
    "total_commission_cat":"Photo Category",
    "total_commission_merchand":"Total Commission Earn By Merchandising",
    "total_st":"Total Stock Take",
    "total_photo":"Total Photo Taken",
    "total_time_spent":"Total Time Spent",
    "stock_promotion_list":"Stock Promotion List",
    "add_stock_promotion":"Add Stock Promotion",
    "expiry_date":"Expiry Date",
    "promotion_content":"Promotion Content",
    "extend_date":"Date is already expired, please change to extend",
    "current_img":"Current Image",
    "product_name":"Product Name",
    "controller":"Controller",
    "operation":"Operation",
    "this_month":"This Month",
    "location":"location",
    "st_report":"Stock Take Report",
    "clear_all_data":"Clear All Data",
    "confirm_clear":"Are you sure you want to delete all the records for the selected location ?",
    "confirm":"Confirm",
    "route_id":"Route ID",
    "drivers":"Drivers",
    "assistant":"Assistant",
    "generate_type":"Generate As",
    "product_uom":"Product UOM",
    "product_desc":"Product Description",
    "barcode":"Barcode",
    "update_success":"Update Successful",
    "update_fail":"Update Failed",
    "new_uom":"Add New Product UOM",
    "edit_uom":"Edit Product UOM",
    "product_code":"Product Code",
    "edit_product_name":"Edit Product Name",
    "outlet_name":"Outlet Name",
    "address1":"Address 1",
    "address2":"Address 2",
    "longitude":"Longitude",
    "latitude":"Latitude",
    "cust_code":"Outlet Code",
    "add_customer":"Add New Outlet",
    "edit_customer":"Edit Customer",
    "out_no_record":"Outlet Visit No Record",
    "oos_no_record":"Out of Stock No Record",
    "comm_no_record":"TotalCommission Merchandising No Record",
    "comc_no_record":"Total Commission Categories No Record",
    "st_no_record":"Total Stock Take No Record",
    "photo_no_record":"Photo Taken No Record",
    "time_no_record":"Total Time Spent No Record",
    "uom":"UOM",
    "new_product":"New Product",
    "active_product":"Active Product",
    "inactive_product":"Inactive Product",
    "add_uom":"Add UOM",
    "delete_uom":"Delete UOM",
    "uomrate":"Rate",
    "schedule_status":"Schedule Status",
    "generate_agent_attend_report":"Generate Agent Attendance Report",
    "generate_stock_take_report":"Generate Stock Take Report",
    "generate_oos_report":"Generate OOS Report",
    "generate_outlet_visit_report":"Generate Outlet Visit Report",
    "generate_outlet_visit_frequency_report":"Generate Outlet Visited Frequency Report",
    "generate_schedule_visit_report":"Generate Scheduled Visit Report",
    "generate_stock_near_expiry_date_report":"Generate Stock Near Expiry Date Report",
    "all":"All",
    "oos":"Out Of Stock",
    "schedule_visit":"Schedule Visit",
    "stock_near_expiry":"Stock Near Expiry",
    "outlet_visit_frequency":"Outlet Visit Frequency",
    "brand":"Brand",
    "add_brand":"Add New Brand",
    "edit_brand":"Edit Brand",
    "category_list":"Category Item List",
    "brand_list":"Brand Item List",
    "category_code":"Category Code",
    "category_name":"Category Name",
    "new_category":"New Category",
    "category_details":"Category Details",
    "brand_details":"Brand Details",
    "category_info":"Category Information",
    "brand_info":"Brand Information",
    "new_category_item":"Add New Category Items",
    "new_brand_item":"Add New Brand Items",
    "update_fail_brand":"Update Failed. Brand Code is Existed",
    "update_fail_category":"Update Failed. Category Code is Existed",
    "brand_img":"Upload Brand Image",
    "remove_brand_image":"Remove Brand Image",
    "cust_category_code":"Outlet Category Code",
    "cust_category_name":"Outlet Category Name",
    "cust_chain_code":"Outlet Chain Code",
    "cust_chain_name":"Outlet Chain Name",
    "new_cust_chain":"New Outlet Chain",
    "new_cust_category":"New Outlet Category",
    "cust_category_list":"Category Outlet List",
    "cust_chain_list":"Chain Outlet List",
    "cust_chain":"Outlet Chain",
    "cust_category":"Outlet Category",
    "cust_category_details":"Outlet Category Info",
    "cust_chain_details":"Outlet Chain Info",
    "update_fail_cust_category":"Update Failed. Customer Category Code is Existed",
    "update_fail_cust_chain":"Update Failed. Customer Chain Code is Existed",
    "template_item_list":"Template Item List",
    "brand_code":"Brand Code",
    "brand_name":"Brand Name",
    "new_brand":"New Brand",
    "mileage": "Mileage",
    "user_settings": "User Settings",
    "user_details": "User Details",
    "email": "Email",
    "contact_no": "Contact No.",
    "name": "Name",
    "login": "Login",
    "resigning": "Resigning User",
    "resign_user": "Resign User",
    "resign_user_confirm1": "Are you sure to resign this user?",
    "resign_user_confirm2": "If yes, please select another user for replacement.",
    "user_access_regions": "User Access Regions",
    "regions_no_data": "No region access for this user.",
    "user_outlet_details": "User Outlet Details",
    "user_outlet_list": "User Outlet List",
    "photo_gallery": "Photo Gallery",
    "check_all":"Check All",
    "all_day": "All Day",
    "due_date":"Due Date",
    "incentive": "Promoter Incentive",
    "stock_level_tooltip": "Enable/disable Stock Level",
    "unsched_outlet_visit": "Unscheduled Outlet Visit",
    "uncheduled": "UNSCHEDULED",
    "cancelled": "CANCELLED",
    "copy_user": "Copy User",
    "copy_user_confirm1": "Copying will replace opponet existing data, please choose carefully.",
    "copy_user_confirm2": "If you have made up your mind, please select which user for replacement.",
    "competitor_price_checking": "Competitor Price checking",
    "competitor": "Competitor",
    "edit_competitor": "Edit Competitor",
    "add_competitor": "Add Competitor",
    "competitor_code": "Competitor Code",
    "competitor_name": "Competitor Name",
    "description": "Description",
    "competitor_details": "Competitor Details",
    "competitor_products": "Competitor Products",
    "competitor_product_category": "Competitor Product Category",
    "add_product": "Add Product",
    "add_category": "Add Category",
    "competitor_product_details": "Competitor Product Details",
    "edit_product": "Edit Product",
    "competitor_category_details": "Competitor Category Details",
    "category_products": "Category Products",
    "assign_product": "Assign Product",
    "edit_category": "Edit Category",
    "missedwreason": "MISSED W/REASON",
    "frequency_list": "Frequency List",
    "posm": "POSM",
    "agent_attend_insight": "Agent Attendance Insight",
    "outlet_visit_insight": "Outlet Visited Insight",
    "area": "Area",
    "rdisplay": "Display %",
    "rsku": "SKU %",
    "new_batch_no": "New Batch No",
    "batch_no": "Batch No",
    "outlet_visit_analysis": "Outlet Visit Analysis",
    "chain": "Chain",
    "table_analysis_report": "Table Analysis Report",
    "rCompetitorPriceCheck": "Competitor Price Check",
    "cust_type": "Outlet Type",
    "cust_type_list": "Type Outlet List",
    "cust_type_code": "Outlet Type Code",
    "cust_type_name": "Outlet Type Name",
    "cust_type_details": "Outlet Type Info",
    "update_fail_cust_type":"Update Failed. Customer Type Code is Existed",
    "new_cust_type":"New Outlet Type",
    "tutorials": "Tutorials",
    "system_settings":"System Settings",
    "photo_type": "Photo Type",
    "photo_taken_analysis": "Photo Taken Analysis",
    "photo_taken": "Photo Taken",
    "download": "Download",
  },
  "zh":{
    "dashboard":"仪表板",
    "transaction":"交易",
    "monthly_sales":"每月销售",
    "semiannual_sales":"半年度销售",
    "logout":"登出",
    "top_salesman":"最佳推销员",
    "top_customer":"最佳顾客",
    "sales":"销售量",
    "outstanding":"未付金额",
    "top_items":"最佳物品",
    "total":"总额",
    "es_backend":"EASYM",
    "proceed_logout":"您正在注销。通过注销，系统将清除缓存数据，包括主题，语言，选择的搜索条件等。您可以在下次登录时重新设置它们。",
    "logout_cap":"登出",
    "cancel":"取消",
    "search_anything":"搜索任何东西",
    "search":"搜索。。。",
    "search_cap":"搜索",
    "clear":"清除",
    "delete":"删除",
    "selected":"已选",
    "settings":"设定",
    "save":"保存",
    "rows_per_page":"每页行",
    "table_info":"交易表信息",
    "search_crit":"条件搜索",
    "today":"今天",
    "yesterday":"昨天",
    "last_week":"过去一周",
    "last_month":"过去一个月",
    "choose_date":"选择日期",
    "doc_date":"文件日期",
    "del_date":"邮寄日期",
    "ok":"好的",
    "active_doc":"有效文件",
    "inactive_doc":"无效文件",
    "pending_doc":"待定文件",
    "salesman":"推销员",
    "customer":"客户",
    "cust_list":"客户列表",
    "no_internet":"您没有连接互联网",
    "doc_details":"文件明细",
    "active_doc_done":"应用内文档已加载",
    "confirm_doc_done":"确认文件已加载",
    "trans_doc_done":"转移文件已加载",
    "posted_doc_done":"文件上載成功",
    "doc_dtl_fail":"文件明细加载失败",
    "inapp_doc":"应用内文件",
    "confirmed_doc":"确认文件",
    "trans_doc":"转移文件",
    "posted_doc":"已上載文件",
    "reset_selection":"重置",
    "no_doc_dtl":"没找到文件明细",
    "user_accounts":"用户账号",
    "no_inapp_doc":"找不到应用内文件",
    "no_confirmed_doc":"找不到确认文件",
    "no_trans_doc":"找不到转移文件",
    "no_posted_doc":"找不到已上載文件",
    "create_selforder":"创建登录自我下单",
    "users":"用户",
    "create_user":"创建用户",
    "user_saved":"用户已创建",
    "user_exists":"用户已存在",
    "operation_fail":"运作失败",
    "operation_success":"Operation Successful",
    "edit_user":"编辑用户 ",
    "username":"用户名",
    "password":"密码",
    "old_pw":"旧密码",
    "new_pw":"新密码",
    "notify_email":"通过电子邮件通知用户",
    "user_email":"用户电子邮件",
    "amend":"修改",
    "load_ua":"用户账户加载中",
    "load_ua_failed":"似乎没有用户记录",
    "username_error":"用户名必须多过两个字符",
    "password_error":"密码必须多过四个字符",
    "unsafe":"用户名和密码不可以包含任何特殊字符",
    "unsafe_username":"用户名包含了任何特殊字符",
    "require_cust":"你必须选择一个客户",
    "chng_pw_title":"更改密码",
    "update":"更新",
    "old_pw_nomatch":"旧密码与用户名不匹配",
    "no_user_update":"旧密码和新密码是一样的",
    "user_updated":"用户密码已经",
    "invalid_email":"无效电子邮件",
    "user_locked":"用户已被锁定",
    "user_unlocked":"用户已经解锁",
    "reconciliation":"和解",
    "date_from":"日期从",
    "date_to":"到日期",
    "checked":"已检查",
    "pending":"待定",
    "missed":"错过",
    "attended":"完成",
    "status":"状态",
    "reject":"拒绝",
    "no_data":"没有找到数据",
    "invoice_list":"发票列表",
    "debit_list":"借方清单",
    "credit_list":"信贷清单",
    "receipt_list":"收据清单",
    "stock":"库存列表",
    "category":"类别",
    "items":"物品",
    "show_items":"显示物品",
    "show_details":"显示详细",
    "search_result":"显示结果",
    "item_dtl":"物品明细",
    "set_virtual_qty":"设置虚拟数量（例如：10％）",
    "current_qty":"当前数量",
    "avbl_qty":"可用数量",
    "virtual_qty":"虚伪数量",
    "customer_low":"客户",
    "record_updated":"数据更新成功",
    "cust_dtl":"客户明细",
    "branches":"分行",
    "choose_vip_item":"选择贵宾数量的物品",
    "vip_items":"贵宾数量的物品",
    "select_item":"请选择一个物品",
    "app_content":"程序內容",
    "upload_adver":"程序廣告",
    "adver_name":"廣告名稱",
    "add_adver":"上載新的廣告",
    "adver_success":"廣告上載成功",
    "adver_fail":"廣告上載失敗",
    "advername_exists":"請使用別的廣告名稱",
    "listview":"列表視圖",
    "gridview":"網格視圖",
    "set_default":"設置成默認",
    "default":"默認",
    "def_del_image":"無法刪除默認圖片",
    "vip_qty":"VIP數量",
    "edit_vip":"設定VIP數量",
    "vip_qty_list":"贵宾数量列表",
    "comment":"評論",
    "attachments":"附件",
    "signed_statements":"簽署聲明",
    "event_items":"活动项目",
    "create_event":"创建活动",
    "events":"活动",
    "search_events":"搜寻活动",
    "event_name":"活动名称",
    "event_desc":"活动描述",
    "event_name_inv":"活动名称无效",
    "close":"关闭",
    "event_same_date":"你将使用相同的开始日期和结束日期保持该活动",
    "proceed_caps":"继续",
    "newevent_created":"已创建新的活动",
    "no_events":"找不到该活动",
    "active":"有效",
    "inactive":"无效",
    "event_updated":"活动详细信息已更新",
    "event_delete":"你将要删除该活动。此活动下的所有货品和规则将会被删除。您要继续吗？",
    "search_by_month":"以月份搜索",
    "create_detail":"创建分类",
    "dtl_name":"分类名称",
    "dtl_desc":"分类描述",
    "dtl_remark":"分类备注",
    "min_qty":"最低数量",
    "min_order_qty":"最低订购数量",
    "edit_min_qty":"更改最低数量",
    "min_qty_list":"最低订购数量列表",
    "min_qty_updated":"最低数量已更新！",
    "min_qty_updated_product":"产品最低数量已更新！",
    "min_qty_failed_update":"最低数量更新失败！",
    "min_qty_deleted":"最低数量已删除",
    "max_qty":"最大数量",
    "adver_popup":"广告更新窗口",
    "adver_old_name":"现名",
    "adver_new_name":"新名字",
    "adver_image":"图片",
    "chng_adver_name_title":"更改广告名称",
    "adver_name_updated":"广告名称已更新",
    "adver_deleted":"广告已删除",
    "no_adver_updated":"没有广告更新",
    "active_adver":"有效",
    "inactive_adver":"无效",
    "show_active_adver":"显示有效广告",
    "show_inactive_adver":"显示无效的广告",
    "failed_retrieve_adver":"检索广告失败",
    "add_announcement":"上载新公告",
    "announcement_success":"公告已上传",
    "announcement_fail":"公告上传失败",
    "announcement_exists":"请使用其他公告名称",
    "announcement_name":"公告名称",
    "announcement_desc":"公告说明",
    "announcement_same_date":"公告日期不能在同一日期",
    "app_announcement":"应用公告",
    "announcement_image":"图片",
    "announcement_current_name":"当前的标题",
    "announcement_new_name":"新标题",
    "announcement_current_desc":"当前说明",
    "announcement_new_desc":"新说明",
    "announcement_current_datefrom":"当前日期从",
    "announcement_new_datefrom":"新日期从",
    "announcement_current_dateto":"当前日期至",
    "announcement_new_dateto":"新日期到",
    "announcement_update_dtl":"更新公告详细信息",
    "announcement_info_updated":"公告详细信息已更新",
    "announcement_info_not_updated":"没有公告更新",
    "announcement_success_deactivated":"公告已成功停用",
    "announcement_failed_deactivated":"无法停用公告",
    "show_active_announcements":"显示活动公告",
    "show_inactive_announcements":"显示无效的公告",
    "failed_retrieve_announcements":"检索公告失败",
    "item_qty":"项目数量",
    "min_purchase":"最低购买量",
    "max_get":"最大物品获得",
    "min_price":"最低价格",
    "max_price":"最高价格",
    "search_item":"搜索产品",
    "custom_price":"定制价格",
    "choose_price":"选择价格",
    "foc":"附送",
    "item_price":"价格",
    "sameitem_foc":"相同产品免费",
    "lowest_foc":"最低价格免费",
    "choose_states":"选择州",
    "postage_fee":"邮费",
    "payment":"付款",
    "upload":"上载",
    "category_img":"上传类别图片",
    "remove_cat_image":"删除类别图片",
    "upload_success":"上传成功",
    "upload_fail":"上传失败",
    "deleted":"已删除！！！",
    "delete_fail":"删除失败",
    "available_qty":"可用数量",
    "price":"价钱",
    "qty":"数量",
    "edit_rec":"更改记录",
    "agent_list":"销售员列表",
    "checkin":"登记",
    "checkin_dtl":"登记详情",
    "checkin_loaded":"登记数据已找到",
    "adver_status":"广告状态已更改",
    "show_all":"显示全部",
    "enter_item_name":"输入物品名称",
    "create_newsubcat":"创建新的细节分类",
    "subcat_list":"细节分类列表",
    "new_subcat":"新细节分类",
    "checkin_sched":"登记时间表",
    "search_agent":"搜寻销售员",
    "appointment_time":"预约在",
    "note_agent":"销售员须知",
    "sel_agent":"请选择销售员",
    "schedules":"时间表",
    "generate":"生成",
    "picking_list":"拾货列表",
    "delivery_info":"交货信息",
    "expected_del_date":"预计交货日期",
    "stock_transfer":"库存转移",
    "stock_take":"库存盘点",
    "stock_take_list":"库存盘点列表",
    "stock_take_details":"库存盘点物品",
    "backend_sync":"后端同步",
    "chg_new_cust":"更改新密码",
    "update_doc_dtl":"更新文件详细资料",
    "doc_info":"文件资讯",
    "cust_tel":"顾客电话号码",
    "deli_add_":"邮寄地址",
    "deli_add_1":"邮寄地址 1",
    "deli_add_2":"邮寄地址 2",
    "deli_add_3":"邮寄地址 3",
    "deli_add_4":"邮寄地址 4",
    "city":"城市",
    "state":"州区",
    "zipcode":"邮政编码",
    "country":"国家",
    "view":"观望",
    "search_item_type":"搜索项目类型",
    "item_not_exist":"记录中不存在该项目",
    "create_type":"创建项目类型",
    "add_item":"添加新项目",
    "knockoff_doc":"已付款文件",
    "no_knockoff_doc":"没有已付款的文件",
    "active_doc_loaded":"有效文件已加载",
    "inactive_doc_loaded":"无效文件已加载",
    "module_linkage":"连锁模块",
    "search_sku_item":"搜索SKU项目",
    "b2b_client":"B2B 客户",
    "b2b_client_list":"B2B客户名单",
    "b2b_client_capital":"B2B客户",
    "unlink_client":"取消链接",
    "link_client":"链接",
    "swap":"交换",
    "b2b_item_list":"B2B项目清单",
    "search_b2b_item":"搜索B2B项目",
    "unlink_item":"未链接产品",
    "search_customer":"搜索客户",
    "open_item":"链接一个产品",
    "room_name":"房间名称",
    "room_desc":"房间描述",
    "room_caseid":"案件编号",
    "open_room":"开启房间",
    "sku_item_list":"SKU项目清单",
    "date_range":"选择日期",
    "change_status":"切换状态",
    "in_range":"In Range",
    "stock_template_items":"Stock Template Items",
    "create_new":"Create New",
    "search_stock_template":"Search stock template",
    "stock_template_name":"Stock template name",
    "newstock_template_created":"New stock template is created",
    "stock_template_updated":"Stock template details is updated",
    "stock_template_deleted":"Stock template deleted",
    "no_stock_template":"No stock template found",
    "add_stock_template_item":"Add Stock Template Items",
    "stock_template_item":"Stock Template Items",
    "individual_item":"Individual Items",
    "item_template_pkg":"Item Template/Package",
    "last_5_inv":"Last 5 Invoices",
    "item_selection":"Item Selection",
    "select_cat_here":"Select category here..",
    "select_item_here":"Select item(s) here..",
    "select_package_here":"Select package here..",
    "select_inv_item_here":"Select invoices item(s) here..",
    "remove":"REMOVE",
    "stock_template_items_added":"Stock template item(s) added",
    "inv_items":"Invoice Items",
    "package":"Package",
    "current_items":"CURRENT ITEM(S)",
    "preview_video":"观看视频",
    "video":"视频",
    "paste_link_here":"在此处粘贴 YouTube 链接...",
    "pdf":"PDF",
    "pdf_list":"PDF列表",
    "preview":"预览",
    "image":"图片",
    "pdf_uploaded":"PDF文件上传成功！",
    "pdf_failed":"PDF文件上传失败",
    "video_uploaded":"视频上传成功！",
    "video_failed":"视频上传失败",
    "not_youtube_link":"这不是 YouTube 链接，请输入/粘贴正确的链接",
    "atch_delete":"您将要删除此附件。确定要继续吗？",
    "no_active_document":"没找到有效文件",
    "person_in_charge":"负责人",
    "set_due_date":"设置截止日期",
    "manage_sched":"管理时间表",
    "pending_sched":"等待中时间表",
    "time":"时间",
    "duration":"持续时间",
    "set_time":"具体时间",
    "set_duration":"具体持续时间",
    "instructions":"任何说明/注意事项?",
    "repeat_sched":"时间表重复",
    "single_customer":"单个客户",
    "multiple_customer":"多个客户",
    "customer_by_filter":"筛选出的顾客",
    "customer_by_area":"以地区找顾客",
    "fulltext_cust":"按地址/地区/州/国家/地区搜索",
    "pic_list":"负责人名单",
    "delete_recur":"删除重复事件",
    "delete_current":"删除当前事件",
    "edit_recur":"编辑重复事件",
    "edit_current":"编辑当前事件",
    "recur_msg":"时间表出现在重复事件。您可以选择对当前事件更改或所有相同事件同时进行更改",
    "target":"目标",
    "insert_success":"添加成功",
    "insert_fail":"添加失败",
    "not_found_target":"没找到目标",
    "not_found_Category":"没找到类别列表",
    "not_found_item":"没找到物品列表",
    "not_found_salesperson":"没找到销售员名单",
    "not_found_item_target":"没找到目标物品",
    "amount_empty":"请输入金额",
    "salesperson":"销售员",
    "edit_amount":"修改金额",
    "date_range_check":"请选择正确的日期，最少一个月",
    "target_empty":"请选择目标",
    "insert_item":"请选择物品",
    "insert_tag":"请选择标签",
    "confirm_update_request":"您要更新该目标信息还是创建新的目标？(新目标回保留该目标所有资料)",
    "submit":"提交",
    "add_new_tg_item":"添加新的物品在这个目标",
    "add_new_tg_tag":"添加新的标签在这个目标",
    "add_new_tag":"添加新的标签:",
    "confirm_update_tg":"确认跟新目标",
    "target_list":"目标",
    "nothing_save":"Nothing to save",
    "template_list":"Template List",
    "new_tmplt":"Create New Template",
    "delete_tmplt":"You are about to delete a template",
    "done":"DONE",
    "edit_tag":"修改标签",
    "yes":"是",
    "no":"不是",
    "category_empty":"请选着类别",
    "confirm_insert_details":"确认添加这个标签",
    "confirm_insert_all_details":"你要复制这标签的所有目标吗吗?",
    "no_attr_selected":"没选取任何属性",
    "empty_tag":"标签为空",
    "edit":"修改",
    "duplicate":"复制",
    "edit_target":"Edit Target",
    "new_target":"Add New Target",
    "tag":"标签",
    "attribute":"属性",
    "generate_commission_report":"生成抽成报告",
    "report":"报告",
    "site_loc":"Site Location",
    "last_visit":"Last Visit",
    "branch":"Branch",
    "note":"Note",
    "phone_number":"Phone Number",
    "search_location":"Search Location",
    "contact_person":"Contact Person",
    "choose_project":"Projects",
    "avg_price_report":"Generate Average Price Report",
    "create_cust_grp":"Create customer group",
    "grp_of":"Group Of",
    "grp_members":"Group Members",
    "cash_rolling":"Cash Rolling",
    "cash_rolling_list":"Cash Rolling List",
    "amount":"金额",
    "new_cash_rolling":"New Cash Rolling",
    "remark":"标注",
    "cash_rolling_report":"Generate Cash Rolling Report",
    "b2border":"B2B Order",
    "item_link_success":"B2B item successfully linked!",
    "item_link_failed":"Failed to link B2B item!",
    "remove_customer_success":"B2B client successfully removed from the customer",
    "assign_customer_success":"B2B client successfully assigned to the customer",
    "assign_customer_failed":"Failed to assign B2B client to the customer",
    "sequence_update_success":"Sequence No. update success",
    "sequence_update_failed":"Sequence No. update failed",
    "driver_routing_report":"Generate Driver Routing Report",
    "must_push_noti":"You must enable push notification first",
    "no_modulename_found":"No module name found",
    "salesperson_list":"Agents",
    "bundle":"Bundle",
    "pwp":"PWP",
    "product":"产品",
    "new_bundle":"Add New Bundle",
    "foc_item":"附送物品",
    "cheaper_item":"折扣产品",
    "out_of":"Out Of",
    "cheaper_price":"折扣后价钱",
    "reportgenerator":"Report Generator",
    "transfer_doc_type_title":"Document Type when Transfer to Acc. System",
    "uom_price":"UOM Price",
    "special_price":"Special Price",
    "delivery_report":"Generate Delivery Report",
    "stock_adjustment":"Stock Adjustment",
    "dob":"Delivery On Behalf",
    "stock_transaction":"Stock Transactions",
    "voucher_report":"Voucher Redeemed Report",
    "order_report":"Order Report",
    "region":"地区",
    "stock_clearance":"Stock Clearance",
    "stock_clearance_list":"Stock Clearance List",
    "summary_report":"Summary Report",
    "doc_type":"Doc Type",
    "type":"Type",
    "teras_dashboard":"Teras Dashboard",
    "outlet_visit":"代理考勤",
    "agent_attend":"代理考勤",
    "out_of_stock":"缺货",
    "total_commission":"总佣金",
    "total_commission_cat":"类别总佣金",
    "total_commission_merchand":"推销总佣金",
    "total_st":"总库存",
    "total_photo":"总照片",
    "total_time_spent":"总花费时间",
    "stock_promotion_list":"Stock Promotion List",
    "add_stock_promotion":"Add Stock Promotion",
    "expiry_date":"过期日期",
    "promotion_content":"Promotion Content",
    "extend_date":"date is already expired, please change to extend",
    "current_img":"现在的图片",
    "product_name":"产品名称",
    "controller":"控制器",
    "operation":"操作",
    "this_month":"这个月",
    "stock_template":"库存模板",
    "location":"地方",
    "st_report":"Stock Take Report",
    "clear_all_data":"Clear All Data",
    "confirm_clear":"Are you sure you want to delete all the records for the selected location ?",
    "confirm":"Confirm",
    "route_id":"Route ID",
    "drivers":"司机",
    "assistant":"助理",
    "generate_type":"创建如一下选择",
    "product_uom":"产品单位",
    "product_desc":"产品描述",
    "barcode":"条码",
    "update_success":"更新成功",
    "update_fail":"更新失败",
    "new_uom":"添加新产品单位",
    "edit_uom":"更新产品单位",
    "product_code":"产品码",
    "edit_product_name":"修改产品名字",
    "outlet_name":"客户名字",
    "address1":"客户地址 1",
    "address2":"客户地址 2",
    "cust_code":"客户编号",
    "add_customer":"新客户",
    "edit_customer":"修改客户",
    "uom":"产品单位",
    "new_product":"新产品",
    "active_product":"有效产品",
    "inactive_product":"无效产品",
    "add_uom":"新产品单位",
    "delete_uom":"删除产品单位",
    "uomrate":"Rate",
    "schedule_status":"预定状态",
    "generate_agent_attend_report":"创建代理考勤报告",
    "generate_stock_take_report":"创建库存盘点报告",
    "generate_oos_report":"创建缺货报告",
    "generate_outlet_visit_report":"创建代理考勤报告",
    "generate_schedule_visit_report":"预定访问报告",
    "generate_stock_near_expiry_date_report":"创建库存过期报告",
    "generate_outlet_visit_frequency_report":"创建客户访问频率报告",
    "all":"全部",
    "oos":"缺货",
    "schedule_visit":"预定访问",
    "stock_near_expiry":"库存过期",
    "outlet_visit_frequency":"客户访问频率",
    "brand":"品牌",
    "add_brand":"添加新品牌",
    "edit_brand":"编辑品牌",
    "category_list":"类别目录",
    "brand_list":"品牌目录",
    "category_code":"类别码",
    "category_name":"类别名称",
    "new_category":"新类别",
    "category_details":"类别明细",
    "brand_details":"品牌明细",
    "category_info":"类别信息",
    "brand_info":"品牌信息",
    "new_category_item":"添加新的类别商品",
    "new_brand_item":"添加新的品牌商品",
    "update_fail_brand":"Update Failed. Brand Code is Existed",
    "update_fail_category":"Update Failed. Category Code is Existed",
    "brand_img":"上传品牌照片",
    "remove_brand_image":"移除品牌照片",
    "cust_category_code":"客户类别码",
    "cust_category_name":"客户类别名称",
    "cust_chain_code":"客户链码",
    "cust_chain_name":"客户链名称",
    "new_cust_chain":"新客户链",
    "new_cust_category":"新客户类别",
    "cust_category_list":"客户类别列表",
    "cust_chain_list":"客户链列表",
    "cust_chain":"客户链",
    "cust_category":"客户类别",
    "cust_category_details":"客户类别信息",
    "cust_chain_details":"客户链信息",
    "update_fail_cust_category":"Update Failed. Customer Category Code is Existed",
    "update_fail_cust_chain":"Update Failed. Customer Chain Code is Existed",
    "template_item_list":"Template Item List",
    "brand_code":"Brand Code",
    "brand_name":"Brand Name",
    "new_brand":"New Brand",
    "mileage": "Mileage",
    "user_settings": "User Settings",
    "user_details": "User Details",
    "email": "电子邮件",
    "contact_no": "联系电话",
    "name": "姓名",
    "login": "Login",
    "resigning": "Resigning User",
    "resign_user": "Resign User",
    "resign_user_confirm1": "Are you sure to resign this user?",
    "resign_user_confirm2": "如果是，请选择其他客户进行替换。",
    "user_access_regions": "客户访问区域",
    "regions_no_data": "该客户没有区域访问权限。",
    "user_outlet_details": "客户插座详细信息",
    "user_outlet_list": "客户插座列表",
    "photo_gallery": "照片库",
    "check_all":"选择所有",
    "all_day": "一整天",
    "due_date":"截止日期",
    "incentive": "促销员激励",
    "stock_level_tooltip": "启用/禁用库存水平",
    "unsched_outlet_visit": "计划外的客户店参观",
    "uncheduled": "计划外",
    "copy_user": "复制用户",
    "cancelled": "取消",
    "copy_user_confirm1": "复制将替换对方现有数据，请谨慎选择。",
    "copy_user_confirm2": "如果您已决定，请选择要更换的用户。",
    "competitor_price_checking": "竞争对手价格检查",
    "competitor": "竞争者",
    "edit_competitor": "编辑竞争对手",
    "add_competitor": "添加竞争对手",
    "competitor_code": "竞争对手代码",
    "competitor_name": "竞争对手名称",
    "description": "描述",
    "competitor_details": "竞争对手详情",
    "competitor_products": "竞争对手产品",
    "competitor_product_category": "竞争对手产品类别",
    "add_product": "添加产品",
    "add_category": "添加类别",
    "competitor_product_details": "竞争对手产品详情",
    "edit_product": "编辑产品",
    "competitor_category_details": "竞争对手类别详细信息",
    "category_products": "类别产品",
    "assign_product": "分配产品",
    "edit_category": "编辑类别",
    "missedwreason": "有理由错过",
    "frequency_list": "频率列表",
    "posm": "POSM",
    "area": "地区",
    "agent_attend_insight": "Agent Attendance Insight",
    "outlet_visit_insight": "Outlet Visited Insight",
    "rdisplay": "Display %",
    "rsku": "SKU %",
    "new_batch_no": "New Batch No",
    "batch_no": "Batch No",
    "outlet_visit_analysis": "Outlet Visit Analysis",
    "chain": "Chain",
    "table_analysis_report": "Table Analysis Report",
    "rCompetitorPriceCheck": "Competitor Price Check",
    "cust_type": "客户类型",
    "cust_type_list": "客户类型列表",
    "cust_type_code": "客户类型链码",
    "cust_type_name": "客户类型名称",
    "cust_type_details": "客户类型信息",
    "update_fail_cust_type":"Update Failed. Customer Type Code is Existed",
    "new_cust_type":"新客户类型",
    "tutorials": "Tutorials",
    "system_settings":"System Settings",
    "photo_type": "Photo Type",
    "photo_taken_analysis": "Photo Taken Analysis",
    "photo_taken": "Photo Taken",
    "download": "Download",
  }
}
export default translations;
