import React, {useContext, useEffect, useState} from 'react';
import JString from "@easytech-international-sdn-bhd/jstring";
import API_Service, {axiosDefaultConfig, axiosURIencode} from "../../Services/API_Service";
import AppContext from "../../Services/AppContext";
import {
    defDateFormat,
    empty,
    req_cust,
    req_delete_file,
    req_cust_area,
    req_gallery,
    req_gallery_checkedPhotos,
    req_gallery_type,
    req_module_setting,
    req_salesman
} from "../../Helper/Constants";
import {de,getSetting} from "../../Helper/Helper";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import GridList from "react-gridlist";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import _ from "lodash";
import {useCommonStyles} from "../../Styles";
import moment from "moment/moment";
import DateRangePickerModal from "../../Components/DateRangePickerModal";
import PersistantData from "../../Services/PersistantData";
import format from "date-fns/format";
import VirtualListCB from "../../Components/VirtualListCB";
import {useSnackbar} from "notistack";
import ImageViewer from '../../Components/ImageViewer';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    viewInRow: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'left',
        marginTop: theme.spacing(1),
        rowGap: 10
    },
    grid: {
        padding: theme.spacing(1),
        height: '100%'
    },
    paper: {
        width: 270,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        zIndex: theme.zIndex + 1,
    },
    maxImage: {
        width: 250,
        height: 300
    },
    largeImage: {
		maxWidth: '100%',
		maxHeight: '100%'
	},
    font: {
        textAlign: 'initial',
    },
    fontGrey: {
        color: 'grey'
    },
}));

function getGridGap(elementWidth, windowHeight) {
    if (elementWidth > 720 && windowHeight > 480) {
        return 10
    } else {
        return 5
    }
}

function getColumnCount(elementWidth, gridGap) {
    return Math.floor((elementWidth + gridGap) / (300 + gridGap))
}

function getWindowMargin(windowHeight) {
    return Math.round(windowHeight * 1.5)
}

export default function Gallery(){
    const [gState] = useContext(AppContext);
    const classes = useStyles();
    const commonStyle = useCommonStyles();
    const singleton = PersistantData.getInstance();
    var {translation: language, client} = gState;
    if (typeof (language) === 'undefined') {
        language = singleton.getlang()
    }
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [splist, setSPlist] = useState([]);
    const [outletList, setOutletList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [imgList, setImgList] = useState([]);
    const [datePickerModal, setDatePickerModal] = useState(false);
    const [galleryDateRange, setGalleryDateRange] = useState({});

    const [agentAttendSpView, setAgentAttendSpView] = useState(false);
    const [agentAttendOutletView, setAgentAttendOutletView] = useState(false);
    const [categoryView, setCategoryView] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState(empty);
    const [agentAttendOutlet, setAgentAttendOutlet] = useState(empty);
    const [agentAttendOutletSelect, setAgentAttendOutletSelected] = useState(empty);
    const [agentAttendSpSelect, setAgentAttendSpSelected] = useState(empty);
    const [agentAttendSalesperson, setAgentAttendSalesperson] = useState(empty);

    const [imageViewer,openImageViewer] = useState('');
    const [checkedState, setCheckedState] = useState([]);

    const [searchByArea, setSearchByArea] = useState(false);
	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

    useEffect(() => {
        if(JString(gState.client).isValid()){
            var axiosGetConfig = axiosDefaultConfig(gState.client);
            // loadGallery(axiosGetConfig);
            getSalesmanList(gState.client);
            getOutletList(gState.client);
            loadGalleryType(axiosGetConfig);
            loadConfig(gState.client);
        }
    }, [gState.client]);

    const getGalleryDate = () => {
        return !_.isEmpty(galleryDateRange) ? moment(galleryDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(galleryDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
    }

    const snack = (msg, type = 'success') => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,
            autoHideDuration: type === 'success' ? 2000 : 3000
        });
    }

    const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customization', {});
				if(_config.searchbyarea){
					setSearchByArea(_config.searchbyarea);
					getAreaList(_client);
					// tableHead.splice(3, 0, {
					// 	name: "cust_area",
					// 	label: "Outlet Area",
					// 	options: {
					// 		display: true
					// 	}
					// });
				}
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
                var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, value} = extraData[idx];
					_data.push({name: name, secondary: value, value: value, status: 1});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

    const loadGallery = (_client = client) =>{
        var st_date = '';
        var st_date_to = '';
        if (!_.isEmpty(galleryDateRange)) {
            st_date = format(galleryDateRange.startDate, defDateFormat);
            st_date_to = format(galleryDateRange.endDate, defDateFormat);
        }

        var parameters = {
            salesperson_id: agentAttendSpSelect,
            cust_code: agentAttendOutletSelect,
            type: selectedCategoryName,
            start_date: st_date,
            end_date: st_date_to,
            area: selectedArea
        };
		if (gState.company) {
			parameters.company = gState.company;
		}

        const params = axiosURIencode(parameters);

        API_Service.get(`${req_gallery}?${params}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (result == 1) {
                setImgList(imgList => (extraData));
            }else{
                setImgList(imgList => ([]));
                snack(language.no_data, 'warning');
            }
            setCheckedState(new Array(extraData.length).fill(false))
        });
    }

    const getSalesmanList = (_client = client) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                var _data = [];
                for (let idx = 0; idx < extraData.length; idx++) {
                    const {name, login_id, login, login_status} = extraData[idx];
                    _data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
                }
                setSPlist(splist => (_data));
            }
        });
    }

    const getOutletList = (_client = client) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            var {customer, inactive_customer} = extraData;
            if (parseInt(result) === 1) {
                var arr = [];
                for (let i = 0; i < customer.length; i++) {
                    const {cust_code, cust_company_name} = customer[i];
                    arr.push({
                        name: cust_company_name,
                        secondary: cust_code,
                        value: cust_code,
                        status: 1
                    });
                }
                for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
                setOutletList(arr);
            }
        });
    }

    const loadGalleryType = (axiosGetConfig) =>{
        API_Service.get(req_gallery_type, axiosGetConfig).then(({data}) => {
            const {result, extraData} = data;
            if (result == 1) {
                var arr = [];
                for (let i = 0; i < extraData.length; i++) {
                    const {type_name, type_status} = extraData[i];
                    arr.push({
                        name: type_name,
                        secondary: type_name,
                        value: type_name,
                        status: parseInt(type_status)
                    });
                }
                setCategoryList(arr);
            }
        });
    }

    const AgentAttendSPViewClose = (newValue) => {
        setAgentAttendSpView(false);
        if (newValue) {
            var spvalue = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    spvalue += newValue[i].value;
                } else {
                    spvalue += newValue[i].value + ",";
                }
            }
            setAgentAttendSpSelected(spvalue);

            var spname = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    spname += newValue[i].name;
                } else {
                    spname += newValue[i].name + ",";
                }
            }
            setAgentAttendSalesperson(spname);
        }
    }

    const AgentAttendOutletViewClose = (newValue) => {
        setAgentAttendOutletView(false);
        if (newValue) {
            var ovalue = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    ovalue += newValue[i].value;
                } else {
                    ovalue += newValue[i].value + ",";
                }
            }
            setAgentAttendOutletSelected(ovalue);

            var oname = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    oname += newValue[i].name;
                } else {
                    oname += newValue[i].name + ",";
                }
            }
            setAgentAttendOutlet(oname);
        }
    }

    const categoryOnClosed = (value) => {
        setCategoryView(false);
        if (value) {
            var data = '';
            for (let i = 0; i < value.length; i++) {
                if (i == value.length - 1) {
                    data += value[i].value.toString();
                } else {
                    data += value[i].value.toString() + ",";
                }
            }
            setSelectedCategoryName(data);
        }
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    const downloadCheckedImages = (_client = client) => {
        let uploadArray = [];
        for (let i = 0; i < imgList.length; i++) {
            if (checkedState[i]) {
                const image = imgList[i];
                uploadArray.push(image.upload_id)
            }
        }

        if (uploadArray.length > 0) {
            const param = axiosURIencode({
                client: client,
                imageArray: uploadArray
            });

            API_Service.get(`${req_gallery_checkedPhotos}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
                var {result, message, extraData} = data;
                if (result) {
                    window.open(extraData.url, '_blank');
                    const delay = setTimeout(() => {
                        API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
                        clearTimeout(delay);
                    }, 5000);
                }
            });
        } else {
            snack("No image selected", 'warning');
        }

    };

    const resetChecked = () => {
        setCheckedState(new Array(imgList.length).fill(false))
    }

    return(
        <div>
            <div className={classes.viewInRow}>
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => setAgentAttendSpView(true)}
                    endIcon={<KeyboardArrowDownRoundedIcon/>}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {agentAttendSalesperson ? _.truncate(agentAttendSalesperson, {length: 20}) : language.salesperson}
                    </Typography>
                </Button>
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => setAgentAttendOutletView(true)}
                    endIcon={<KeyboardArrowDownRoundedIcon/>}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {agentAttendOutlet ? _.truncate(agentAttendOutlet, {length: 20}) : language.customer}
                    </Typography>
                </Button>
                {
					searchByArea && (
						<Button
							style={{width: 300, marginRight: 10}}
							variant="outlined"
							onClick={() => AreaViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
							</Typography>
						</Button>
					)
				}
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => setCategoryView(true)}
                    endIcon={<KeyboardArrowDownRoundedIcon/>}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {selectedCategoryName ? _.truncate(selectedCategoryName, {length: 20}) : language.photo_type}
                    </Typography>
                </Button>
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => setDatePickerModal(true)}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {getGalleryDate()}
                    </Typography>
                </Button>
                <Button
                    size="small"
                    color="primary"
                    variant={'contained'}
                    onClick={() => loadGallery()}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {language.view}
                    </Typography>
                </Button>
                <div style={{width: 10}}/>
                <Button
                    size="small"
                    color="primary"
                    variant={'contained'}
                    onClick={() => downloadCheckedImages()}>
                    <Typography className={commonStyle.searchBtnFont}>
                        Download
                    </Typography>
                </Button>
                <div style={{width: 10}}/>
                <Button
                    size="small"
                    color="primary"
                    variant={'contained'}
                    onClick={() => resetChecked()}>
                    <Typography className={commonStyle.searchBtnFont}>
                        Reset
                    </Typography>
                </Button>
            </div>
            <div style={{paddingTop: 40}}>
                {
                    imgList.length > 0 && (
                        <GridList
                            items={imgList}
                            getGridGap={getGridGap}
                            getColumnCount={getColumnCount}
                            getWindowMargin={getWindowMargin}
                            getItemData={(item, cwidth) => {
                                let adjustedHeight = Math.round(cwidth * 1.12)
                                return {
                                    key: item.upload_image,
                                    height: 600,
                                }
                            }}
                            renderItem={(image) => {
                                return (
                                    <Grid container spacing={3} className={classes.grid}>
                                        <Paper className={classes.paper}>
                                            <img src={image.upload_image} onClick={ () => openImageViewer(image.upload_image) } className={classes.maxImage} />
                                            <ImageViewer
                                                open={!_.isEmpty(imageViewer)}
                                                src={imageViewer}
                                                onDismiss={() => {
                                                    openImageViewer('')
                                                }}
                                                imageStyle={classes.largeImage}
                                            />
                                            <Typography variant="inherit" component="p" className={classes.fontGrey+' '+classes.font}>
                                                {image.cust_code}
                                            </Typography>
                                            <Typography variant="inherit" component="h4" className={classes.font}>
                                                {image.cust_company_name}
                                            </Typography>
                                            <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                <span style={{ width: '80px' }}>Chain:</span><span style={{ width: '70%' }}>{image.cust_chain_name}</span>
                                            </Typography>
                                            {
                                                searchByArea && (
                                                    <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                        <span style={{ width: '80px' }}>Area:</span><span style={{ width: '70%' }}>{image.cust_area}</span>
                                                    </Typography>
                                                )
                                            }
                                            <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                <span style={{ width: '80px' }}>Category:</span><span style={{ width: '70%' }}>{image.cust_category_name}</span>
                                            </Typography>
                                            <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                <span style={{ width: '80px' }}>Agent:</span><span style={{ width: '70%' }}>{image.name}</span>
                                            </Typography>
                                            <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                <span style={{ width: '80px' }}>Remark:</span><span style={{ width: '70%' }}>{image.upload_remark}</span>
                                            </Typography>
                                            <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                <span style={{ width: '80px' }}>Type:</span><span style={{ width: '70%' }}>{image.upload_type_name}</span>
                                            </Typography>
                                            {
                                                image?.company && (
                                                    <Typography variant="inherit" component="p" className={classes.font} style={{ display: 'flex' }}>
                                                        <span style={{ width: '80px' }}>Branch:</span><span style={{ width: '70%' }}>{image.company.toUpperCase()}</span>
                                                    </Typography>
                                                )
                                            }
                                            <Typography variant="inherit" component="p" className={classes.font}>
                                                {moment(image.taken_date).format('DD/MM/YYYY HH:mmA')}
                                                <Checkbox
                                                    checked = {checkedState[imgList.indexOf(image)]}
                                                    onChange={function(){ handleOnChange(imgList.indexOf(image)) }}
                                                    color={'primary'}
                                                />
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )
                            }}
                        />
                    )
                }
            </div>

            <VirtualListCB
                open={agentAttendSpView}
                setClose={data => AgentAttendSPViewClose(data)}
                title={language.salesperson}
                data={splist}
                language={language}
                filteration={1}
            />

            <VirtualListCB
                open={agentAttendOutletView}
                setClose={data => AgentAttendOutletViewClose(data)}
                title={"Outlet"}
                data={outletList}
                language={language}
                filteration={1}
            />

            <VirtualListCB
                open={categoryView}
                setClose={data => categoryOnClosed(data)}
                title={language.photo_type}
                data={categoryList}
                language={language}
                filteration={1}
            />

            <VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
                filteration={0}
			/>

            <DateRangePickerModal
                setPositive={range => {
                    setGalleryDateRange(range);
                    setDatePickerModal(false)
                }}
                setNegative={() => {
                    setDatePickerModal(false)
                }}
                setClose={() => {
                    setDatePickerModal(false)
                }}
                open={datePickerModal}
                message={language.date_range}
                positive={language.ok}
            />
        </div>
    )
}
