import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import 'mapbox-gl/dist/mapbox-gl.css';
import IconButton from "@material-ui/core/IconButton";
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import AppContext from '../../Services/AppContext';
import {Button, Chip, DialogContent, Grid, Paper} from '@material-ui/core';
import {req_chain_cust, req_cust_list} from '../../Helper/Constants';
import MUIDataTable from 'mui-datatables';
import VirtualListCB from '../VirtualListCB';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from "@material-ui/core/Tooltip";
import _ from 'lodash';
import EditCustChain from '../EditCustChain';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	appBar: {
		position: 'relative',
	},
	root: {
		flexGrow: 1
	},
	grid: {
		marginTop: 10,
		paddingLeft: 20,
		paddingRight: 20
	},
	dlgContent: {margin: -20, marginTop: -10},
	paper: {
		padding: 10
	},
	paperMaxHeight: {
		padding: 10,
		maxHeight: 120
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustChainDetails({
											item: currentItem,
											open,
											setClose,
											language,
											onUpdateCustChain,
											onSnack,
											integration
										}) {
	const [gState] = useContext(AppContext);
	const classes = useStyles();
	const [axiosConfig, setAxiosConfig] = useState({});
	const [tabPosition, setTabPosition] = useState(0);
	const [item, setItem] = useState(currentItem);
	const [showDropzone, toggleDropZone] = useState(true);
	const [openCustChainEdit, setOpenCustChainEdit] = useState(false);
	const [chainImage, setChainImage] = useState(null);
	const [custChainData, setCustChainData] = useState([]);
	const [newItemListOpen, setNewItemListOpen] = useState(false);
	const [newItemList, setNewItemList] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			setAxiosConfig(axiosGetConfig);
		}
	}, [gState?.client]);

	useEffect(() => {
		if (!_.isEmpty(currentItem) && !_.isEmpty(axiosConfig)) {
			loadCustChainList();
			loadNewCustomerList();
		}
	}, [currentItem, axiosConfig]);

	const onClose = () => {

		setClose();
	}

	const editCustChain = (data) => {
		setOpenCustChainEdit(false);
		if (data) {
			setItem(data[0]);
			onUpdateCustChain();
		}
	}

	const loadCustChainList = (config = axiosConfig) => {
		API_Service.get(`${req_cust_list}?cust_chain_id=${item.cust_chain_id}`, _.isEmpty(config) ? axiosConfig : config).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				onSnack(language.no_data, 'warning');
			} else {
				setCustChainData(() => (extraData));
			}
		});
	}

	const loadNewCustomerList = (config = axiosConfig) => {
		API_Service.get(req_cust_list, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _arr = [];
				for (let i = 0; i < extraData.length; i++) {
					const {cust_code, cust_company_name, customer_status, cust_chain} = extraData[i];
					if (customer_status === '1' && cust_chain === '0') {
						_arr.push({
							name: cust_company_name,
							secondary: cust_code,
							value: cust_code,
							status: parseInt(customer_status)
						});
					}
				}

				if (_arr.length > 0) {
					setNewItemList(newItemList => (_arr));
				} else {
					onSnack(language.no_data, 'warning');
				}
			}
		});
	}

	const custChainViewClose = (newValue) => {
		setNewItemListOpen(false);
		if (newValue) {
			if (newValue['name'] != 'reset') {
				var formdata = new FormData();
				formdata.append('cust_chain_id', item.cust_chain_id);
				formdata.append('customer', JSON.stringify(newValue));

				API_Service.post(req_chain_cust, formdata, axiosConfig).then(({data}) => {
					var {result, extraData} = data;
					result = JString(result).toNumber();
					if (result === 1) {

						setCustChainData(() => (extraData ? extraData : []));
						loadNewCustomerList();
						onSnack && onSnack(language.record_updated, 'success');
					} else {
						onSnack && onSnack(language.operation_fail, 'error');
					}
				});
			}
		} else {

		}
	}

	return (
		<div className={classes.root}>
			<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{language.cust_chain_details}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent className={classes.dlgContent}>
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={4}>
							<Paper className={classes.paper}>
								<Grid container spacing={2}>
									<Grid item xs={12} lg direction="column" container>
										<Typography variant="subtitle1" className={classes.textleft}>
											{item.cust_chain_code}
										</Typography>
										<Typography variant="inherit" component="h4" className={classes.textleft}>
											{item.cust_chain_name}
										</Typography>
									</Grid>
									{
										integration === '0' && (
											<Button
												onClick={() => setOpenCustChainEdit(true)}
												size="small">
												<EditIcon/>
											</Button>
										)
									}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={8}>
							<CustChainItemList
								data={custChainData}
								language={language}
								gState={gState}
								onEvent={() => loadCustChainList()}
								onSnack={onSnack}
								setNewItemListOpen={setNewItemListOpen}
								item={item}
							/>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<EditCustChain
				open={openCustChainEdit}
				onClose={(data) => editCustChain(data)}
				language={language}
				cust_chain_code={item.cust_chain_code}
				cust_chain_name={item.cust_chain_name}
			/>
			{
				newItemListOpen && (
					<VirtualListCB
						setClose={(d) => custChainViewClose(d)}
						open={newItemListOpen}
						title={'New Customer'}
						data={newItemList}
						language={language}
						filteration={0}
					/>
				)
			}
		</div>
	)
}

function Space({height = 10}) {
	return (
		<div style={{height}}/>
	)
}

function CustChainItemList({data, language, gState, onEvent, onSnack, setNewItemListOpen, item}) {
	const onRowDelete = (row) => {
		const _arr = [];
		for (let i = 0; i < row.length; i++) {
			var {dataIndex} = row[i];
			_arr.push(data[dataIndex]['cust_code']);
		}
		var axiosPut = axiosPutConfig(gState.client);
		var params = axiosURIencode({
			customer: _arr.join(","),
			ori_code: item.cust_chain_id
		});

		API_Service.put(req_chain_cust, params, axiosPut).then(({data}) => {
			var {result} = data;
			result = JString(result).toNumber();
			if (parseInt(result) === 1) {
				onSnack(language.record_updated, 'success');
			} else {
				onSnack(language.operation_fail, 'error');
			}
			onEvent();
		});
	}
	return (
		<MUIDataTable
			title={language.cust_list}
			data={data || []}
			columns={itemDataCell}
			options={{
				print: false,
				filter: false,
				rowsPerPage: 99,
				rowsPerPageOptions: [125, 140, 165, 200],
				responsive: 'simple',
				download: false,
				viewColumns: false,
				selectableRows: data.length > 0 ? 'multiple' : 'none',
				onRowsDelete: ({data: row}) => onRowDelete(row),
				setTableProps: () => ({size: 'small'}),
				customToolbar: () =>
					<TemplateTableCustomToolbar
						addChainCust={() => {
							setNewItemListOpen(true)
						}}
					/>
			}}
		/>
	)
}

const itemDataCell = [
	{
		name: "cust_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "created_date",
		label: "Created Date",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "updated_at",
		label: "Last Updated",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
				)
			}
		}
	},
	{
		name: "customer_status",
		label: "Status",
		options: {
			display: true,
			customBodyRender: (value) => {
				switch (value) {
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "0" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>);
						break;
					default :
						return (null);
						break;
				}
			}
		}
	}
];

function TemplateTableCustomToolbar({addChainCust}) {
	return (
		<React.Fragment>
			<Tooltip title={'Add Into Chain'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => addChainCust()} aria-label="addnew">
					<AddIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
