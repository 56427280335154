import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import JString from '@easytech-international-sdn-bhd/jstring';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import {
    pdf_image,
    req_agentcust,
    req_checkin_sched_amend,
    req_coord,
    req_cust,
    req_cust_attachment,
    req_cust_delivery,
    req_cust_info,
    req_cust_itemtype,
    req_cust_sched,
    req_cust_statement,
    req_custsalesman,
    req_module_setting,
    req_stock,
    req_stock_itemtype,
    req_stock_itemtypedtl,
	req_cust_tmplt,
    reset
} from '../../Helper/Constants';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import API_Service, {axiosURIencode} from '../../Services/API_Service';
import moment from 'moment';
import _ from 'lodash';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import {usePaperStyles} from '../../Styles/StylePaper';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import {Button, ButtonGroup, CircularProgress, TextField} from '@material-ui/core';
import AutoCompleteSearch from '../AutoCompleteSearch';
import {useCommonStyles} from '../../Styles';
import {Done, KeyboardArrowDownRounded} from '@material-ui/icons';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import InfoIcon from '@material-ui/icons/Info';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {arrayBufferToBase64, de, getSetting} from '../../Helper/Helper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchBox from '../SearchBox';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import {blue, grey, red} from '@material-ui/core/colors';
import VirtualList from '../VirtualList';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CircularProgressWithLabel from '../CircularProgressWithLabel.js';
import EditIcon from '@material-ui/icons/Edit';
import {DropzoneArea} from 'material-ui-dropzone';
import MediaBox from '../MediaBox';
import ImageViewer from '../ImageViewer';
import Alert from '../Alert';
import AddCustomer from '../../Components/AddCustomer';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const deliveryHeadCell = [
	{
		name: 'id',
		label: 'id',
		options: {
			display: false,
			filter: false,
			print: false
		}
	},
	{
		name: "delivery_period",
		label: "Period",
		options: {
			display: true
		}
	},
	{
		name: "delivery_interval",
		label: "Interval",
		options: {
			display: true
		}
	}
];
const headCells = [
	{
		name: 'id',
		label: 'id',
		options: {
			display: false,
			filter: false,
			print: false
		}
	},
	{
		name: "branch_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "branch_attn",
		label: "Attendee",
		options: {
			display: true
		}
	},
	{
		name: "branch_phone",
		label: "Phone",
		options: {
			display: true
		}
	}
];

const stockHead = [
	{
		name: 'id',
		label: 'id',
		options: {
			display: false,
			print: false
		}
	},
	{
		name: "tmplt_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "active_status",
		label: "Status",
		options: {
			display: true,
			customBodyRender: (value) => {
				switch (value) {
					case "1" :
						return ("Active");
						break;
					case "0" :
						return ("Inactive");
						break;
					default :
						return ("Inactive");
						break;
				}
			}
		}
	},
	{
		name: "created_at",
		label: "Created At",
		options: {
			display: true,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')
				)
			}
		}
	},
	{
		name: "created_by",
		label: "Created By",
		options: {
			display: true
		}
	}
];

const schedHeadCell = [
	{
		name: 'id',
		label: "ID",
		options: {
			display: false,
			filter: false
		}
	},
	{
		name: 'salesperson_id',
		label: "SID",
		options: {
			display: false,
			filter: false
		}
	},
	{
		name: 'name',
		label: "Agent",
		options: {
			display: true
		}
	},
	{
		name: 'sched_datetime',
		label: "Appointment At",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm A')
				)
			}
		}
	},
	{
		name: 'sched_note',
		label: "Note",
		options: {
			display: true
		}
	}
];

const useStyles = makeStyles((theme) => ({
	largeImage: {
		maxWidth: 450,
		maxHeight: 450
	},
	newType: {
		width: '90%'
	},
	card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	cardChosen: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		backgroundColor: theme.palette.secondary.light
	},
	appBar: {
		position: 'relative',
	},
	saveBtn: {
		marginTop: theme.spacing(2),
	},
	input: {
		marginTop: theme.spacing(2),
		width: '100%'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginBottom: 10
	},
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'flex-start',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1
	},
	paperCenter: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 300,
	},
	grid: {
		padding: theme.spacing(1),
		maxWidth: '100%'
	},
	textleft: {
		textAlign: "left"
	},
	maxImage: {
		maxWidth: 250,
		maxHeight: 280
	},
	marginTop: {
		marginTop: theme.spacing(1)
	},
	chooseButton: {
		width: '100%',
		height: theme.spacing(5),
		justifyContent: 'left',
		marginTop: theme.spacing(1)
	},
	schedAddSection: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		marginTop: 0
	},
	schedNote: {
		width: '30%'
	},
	rowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	bottomNav: {
		position: 'fixed',
		bottom: 0,
		top: 'auto',
		width: '100%'
	},
	paperList: {
		marginBottom: 80,
		padding: theme.spacing(1)
	},
	flexBtn: {
		flexGrow: 1,
		display: 'flex'
	},
	coordRoot: {
		'& .MuiTextField-root': {
			width: '100%',
			marginBottom: theme.spacing(1)
		},
		marginBottom: 80,
		padding: theme.spacing(1)
	},
	dropzoneText: {
		color: grey[600]
	},
	divWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={true}
			format={'###### UNIT(s)'}
			isNumericString
			suffix="%"
			min={0}
			max={99}
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

const labels = {
	cust_code: "Code",
	cust_company_name: "Name",
	billing_address: "Billing",
	created_date: "Created At",
	region: "Region",
	latitude: "Latitude",
	longitude: "Longitude",
	// latlong: "Latitude,Longtitude",
	cust_category_name: "Customer Category",
	cust_chain_name : "Customer Chain",
	cust_type_name : "Customer Type",
	company : "Branch"
	// current_balance: "Outstanding"
};

const deliverySchedule = [
	{
		name: "Next Day",
		value: "1",
		options: [],
		type: "select"
	},
	{
		name: "Every Week",
		value: "2",
		options: [
			{
				cname: "Monday",
				cvalue: "1"
			},
			{
				cname: "Tuesday",
				cvalue: "2"
			},
			{
				cname: "Wednesday",
				cvalue: "3"
			},
			{
				cname: "Thursday",
				cvalue: "4"
			},
			{
				cname: "Friday",
				cvalue: "5"
			},
			{
				cname: "Saturday",
				cvalue: "6"
			},
			{
				cname: "Sunday",
				cvalue: "7"
			}
		],
		type: "multiselect"
	},
	{
		name: "Every Month",
		value: "3",
		options: [],
		type: "date"
	}
];
const lengthLimit = {
	case_name: 25,
	case_desc: 50,
	case_id: 25
};
export default function CustomerDetails({
											customer: currentCustomer,
											open,
											setClose,
											language,
											axiosConfig,
											axiosPut,
											availableAgents,
											onSnack,
											config,
											groups,
											onUpdateCustomer,
											integration,
											custCategoryList,
											custChainList,
											custTypeList,
											branchesList
										}) {
	const classes = useStyles();
	const rowClass = usePaperStyles();
	const commonStyle = useCommonStyles();

	const [updatedInfo, setUpdatedInfo] = useState({});
	const [viewImage, setViewImage] = useState('');
	const [attachIdToDelete, setAttachIdToDelete] = useState({});
	const [attachments, setAttachments] = useState({});
	const [segmentAttachment, setSegmentAttachment] = useState(0);
	const [showDropzoneImage, setShowImageDropZone] = useState(true);
	const [showDropzonePdf, setShowPdfDropZone] = useState(true);
	const [imageUploadContent, setImageUploadContent] = useState(null);
	const [pdfUploadContent, setPdfUploadContent] = useState(null);
	const [agentListLoading, setAgentListLoading] = useState(0);
	const [branches, setBranches] = useState([]);
	const [noCustBranch, setNoCustBranch] = useState(false);
	const [signedStatements, setSignedStatements] = useState([]);
	const [agentList, setAgentList] = useState([]);
	const [schedList, setSchedList] = useState([]);
	const [schedAgentList, setSchedAgentList] = useState([]);
	const [schedDate, setSchedDate] = useState(new Date());
	const [schedAgent, setSchedAgent] = useState(0);
	const [schedNote, setSchedNote] = useState('');
	const [existingSchedId, setExistingSchedId] = useState(0);
	const [deliveryInfoMenu, setDeliveryInfoMenu] = useState(null);
	const [deliveryType, setDeliveryType] = useState(deliverySchedule[0]);
	const [deliverySubInfoMenu, setDeliverySubInfoMenu] = useState(null);
	const [deliveryTypeSub, setDeliveryTypeSub] = useState('');
	const [deliveryMonthlyDate, setDeliveryMonthlyDate] = useState(new Date());
	const [deliveryData, setDeliveryData] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [custItemType, setcustItemType] = useState([]);
	const [itemType, setItemType] = useState([]);
	const [searchItemType, setSearchItemType] = useState({});
	const [itemTypeDtl, setItemTypeDtl] = useState([]);
	const [changingItemType, setChangingItemType] = useState(-1);
	const [itemList, setItemList] = useState([]);
	const [showItemList, setShowItemList] = useState({});
	const [highlightRow, setHighlightRow] = useState(-1);
	const [newItemType, setNewItemType] = useState('');
	const [showNewDtl, setShowNewDtl] = useState(false);
	const [typeCode, setTypeCode] = useState('');
	const [stockTemplate, setStockTemplate] = useState([]);

	const [customerGroups, setCustomerGroups] = useState({});
	const [orderType, setOrderType] = useState('');

	const [caseName, setCaseName] = useState('');
	const [caseDesc, setCaseDesc] = useState('');
	const [caseId, setCaseId] = useState('');

	const [activeCoord, setActiveCoord] = useState([]);
	const [editingCoordIdx, setEditingCoordIdx] = useState(-1);
	const [updatingCoord, setUpdatingCoord] = useState(false);

	const [editDialog, setEditDialog] = useState(false);
	const [customer, setCurrentCustomer] = useState(currentCustomer);

	const onClose = () => {
		setAgentListLoading(0);
		setBranches([]);
		setNoCustBranch(false);
		setSignedStatements([]);
		setAgentList([]);
		setSchedList([]);
		setSchedAgentList([]);
		setSchedDate(new Date());
		setSchedAgent(0);
		setSchedNote('');
		setClose();
		setDeliveryInfoMenu(null);
		setDeliveryType(deliverySchedule[0].name);
		setcustItemType([]);
		setItemType([]);
		setSearchItemType({});
		setItemTypeDtl([]);
		setChangingItemType(-1);
		setItemList([]);
		setShowItemList({});
		setHighlightRow(-1);
		setNewItemType('');
		setShowNewDtl(false);
		setTypeCode('');
		setCaseName('');
		setCaseDesc('');
		setCaseId('');
		setExistingSchedId(0);
		setAttachments({});
		setSegmentAttachment(0);
		setOrderType({});
		setCustomerGroups({});
		setStockTemplate([]);
	}

	useEffect(() => {
		if (config?.orderType && config?.orderType instanceof Array) {
			const _order_type = JString(updatedInfo?.order_type).toJson();
			setOrderType(_order_type?.[0]);
		}
	}, [config, updatedInfo]);

	useEffect(() => {
		if (JString(currentCustomer.cust_code).isValid() && open) {
			var params = {
				branch: 1,
				cust_code: currentCustomer.cust_code
			};
			params = axiosURIencode(params);
			API_Service.get(`${req_cust}?${params}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				if (result === 0) {
					setNoCustBranch(noCustBranch => (true));
				} else {
					setBranches(branches => (extraData));
					setNoCustBranch(noCustBranch => (false));
				}
			});
			params = axiosURIencode({
				cust_code: currentCustomer.cust_code,
				with_product: 0
			});
			API_Service.get(`${req_cust_info}?${params}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				if (parseInt(result) === 1) {
					const _updatedInfo = extraData[0];
					setUpdatedInfo(_updatedInfo);
				}
			});
			params = axiosURIencode({
				cust_code: currentCustomer.cust_code
			});
			API_Service.get(`${req_cust_tmplt}?${params}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				if (parseInt(result) === 1) {
					setStockTemplate(extraData);
				}
			});
			loadAgents();
			loadCustomerSignedStatements();
			loadCustomerSchedules();
			// loadCustomerItemType();
			// loadStockItemType();
			// loadDeliveryInfo();
			loadStocksList();
			loadAttachments();
			// loadActiveCoord();
			const tmp = [];
			for (let i = 0; i < availableAgents.length; i++) {
				const {name, login_id} = availableAgents[i];
				tmp.push({
					key: login_id,
					value: name
				});
			}
			setSchedAgentList(tmp);
		}
	}, [open]);

	useEffect(() => {
		if (open && groups && Object.keys(groups).length > 0) {
			loadCustomerGroups();
		}
	}, [open]);

	useEffect(()=>{
		setCurrentCustomer(currentCustomer);
	},[currentCustomer]);

	const loadCustomerGroups = () => {
		API_Service.get(req_module_setting, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _customerGroups = getSetting(extraData, 'customer_groups', {});
				setCustomerGroups(_customerGroups);
			}
		});
	}

	const loadAttachments = (content_type = '') => {
		var list = {pdf: attachments?.pdf || [], image: attachments?.image || []};
		setAttachments({});
		var params = {
			cust_code: currentCustomer.cust_code,
			content_type: content_type
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_cust_attachment}?${params}`, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				for (let i = 0; i < extraData.length; i++) {
					const obj = extraData[i];
					const {content_type: type} = obj;
					if (type == 'pdf') {
						list.pdf.push(obj);
					}
					if (type == 'image') {
						list.image.push(obj);
					}
				}
			} else {
				if (content_type == 'pdf') {
					list.pdf = [];
				}
				if (content_type == 'image') {
					list.image = []
				}
			}
			setAttachments(list);
		}).catch(de);
	}

	const loadStocksList = () => {
		var req = `${req_stock}-1`;
		API_Service.get(req, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			const {stock: stockApi} = extraData;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < stockApi.length; idx++) {
					const {product_name, product_code} = stockApi[idx];
					_data.push({name: product_name, value: product_code});
				}
				_data.push({name: language.stock, value: reset});
				setItemList(itemList => (_data));
			}
			de(data);
		}).catch(de);
	}

	const loadDeliveryInfo = () => {
		setDeliveryData([]);
		var params = {
			cust_code: currentCustomer.cust_code
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_cust_delivery}?${params}`, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			if (result) {
				setDeliveryData(extraData || []);
			}
		}).catch(console.warn);
	}

	const loadAgents = () => {
		var params = {
			cust_id: currentCustomer.cust_id
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_custsalesman}?${params}`, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			if (result) {
				setAgentList(extraData);
			}
		}).catch(console.warn);
	}

	const loadCustomerSignedStatements = () => {
		var params = {
			cust_code: currentCustomer.cust_code
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_cust_statement}?${params}`, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			if (result == 1) {
				setSignedStatements(signedStatements => (extraData));
			}
		}).catch(console.warn);
	}

	const loadCustomerSchedules = () => {
		var params = {
			cust_code: currentCustomer.cust_code
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_cust_sched}?${params}`, axiosConfig).then(({data}) => {
			var {result, extraData, message} = data;
			if (result == 1) {
				setSchedList(extraData);
			}
		}).catch(console.warn);
	}

	const loadCustomerItemType = () => {
		var params = {
			cust_code: currentCustomer.cust_code
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_cust_itemtype}?${params}`, axiosConfig).then(({data}) => {
			var {result, extraData, message} = data;
			setcustItemType([]);
			if (result == 1) {
				const arr = [];
				for (let index = 0; index < extraData.length; index++) {
					const {type_code} = extraData[index];
					arr.push(type_code);
				}
				setcustItemType(arr);
			}
		}).catch(console.warn);
	}

	const loadStockItemType = () => {
		setItemType([]);
		API_Service.get(`${req_stock_itemtype}`, axiosConfig).then(({data}) => {
			var {result, extraData, message} = data;
			if (result == 1) {
				setItemType(extraData);
			}
		}).catch(console.warn);
	}

	const toggleAgent = (login_id, status, company) => {
		var params = {
			cust_code: currentCustomer.cust_code,
			login_id,
			status
		};
		if (company) {
			params.company = company;
		}
		setAgentListLoading(login_id);
		params = axiosURIencode(params);
		
		API_Service.put(req_agentcust, params, axiosPut).then(({data}) => {
			var {result, extraData, message} = data;
			if (result == 1) {
				setAgentList(agentList => (extraData));
			} else {
				onSnack && onSnack({msg: message, type: 'error'})
			}
			setAgentListLoading(0);
		}).catch((err) => {
			setAgentListLoading(0);
		});
	}

	const onAttachmentDelete = () => {
		const id = attachIdToDelete.id;
		var params = axiosURIencode({
			id
		});
		API_Service.put(req_cust_attachment, params, axiosPut).then(({data}) => {
			var {result, message} = data;
			if (result == 1) {
				if (attachIdToDelete.type == 'pdf') {
					loadAttachments('pdf');
				}
				if (attachIdToDelete.type == 'image') {
					loadAttachments('image');
				}
			} else {
				onSnack && onSnack({msg: message, type: 'error'})
			}
			setAttachIdToDelete({});
		}).catch((err) => {
			setAttachIdToDelete({});
		});
	}

	const onDeleteAction = (id, type) => {
		setAttachIdToDelete({id, type});
	}

	const onAttachmentPost = (type = 'image') => {
		var content = imageUploadContent;
		if (type == 'pdf') {
			content = pdfUploadContent;
			setShowPdfDropZone(false);
		}
		if (type == 'image') {
			setShowImageDropZone(false);
		}
		content = arrayBufferToBase64(content);
		const formdata = new FormData();
		formdata.append('cust_code', currentCustomer.cust_code);
		formdata.append('content_type', type);
		formdata.append('link', content);

		API_Service.post(req_cust_attachment, formdata, axiosConfig).then(({data}) => {
			const {result, message} = data;
			if (result == '1') {
				loadAttachments(type);
				if (type == 'pdf') {
					setPdfUploadContent(null);
					setShowPdfDropZone(true);
				}
				if (type == 'image') {
					setImageUploadContent(null);
					setShowImageDropZone(true);
				}
			} else {
				onSnack && onSnack({msg: message, type: 'error'});
			}
		});
	}

	const onSchedPost = () => {
		if (parseInt(schedAgent) < 1) {
			onSnack && onSnack({msg: language.sel_agent, type: 'error'});
			return;
		}
		const formdata = new FormData();
		formdata.append('cust_code', currentCustomer.cust_code);
		formdata.append('salesperson_id', schedAgent);
		formdata.append('sched_date', moment(schedDate).format('YYYY-MM-DD HH:mm'));
		formdata.append('sched_note', schedNote);

		API_Service.post(req_cust_sched, formdata, axiosConfig).then(({data}) => {
			const {result, extraData, message} = data;
			if (result) {
				setSchedList(extraData);
				onSnack && onSnack({msg: language.record_updated, type: 'success'});
			} else {
				onSnack && onSnack({msg: message, type: 'error'});
			}
		}).catch(console.warn);
	}

	const onSchedUpdate = () => {
		const params = axiosURIencode({
			id: existingSchedId,
			sched_datetime: moment(schedDate).format('YYYY-MM-DD HH:mm'),
			salesperson_id: schedAgent,
			sched_note: schedNote || '',
			tech_assigned: schedAgent
		});
		API_Service.put(req_checkin_sched_amend, params, axiosPut).then(({data}) => {
			const {result, extraData, message} = data;
			if (result) {
				setSchedAgent(0);
				setSchedDate(new Date());
				setExistingSchedId(0);
				setSchedNote('');
				loadCustomerSchedules();
				onSnack && onSnack({msg: language.record_updated, type: 'success'});
			} else {
				onSnack && onSnack({msg: message, type: 'error'});
			}
		});
	}

	const onDeleteSched = (data) => {
		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {id} = schedList[dataIndex];
			ids += JString(id).toString();
			if (index != (size - 1) && size != 1) {
				ids += ',';
			}
		}
		var params = axiosURIencode({
			doc_ids: ids
		});
		API_Service.put(req_cust_sched, params, axiosPut).then(({data}) => {
			const {result} = data;
			if (!result) {
				onSnack && onSnack({msg: language.operation_fail, type: 'error'});
			}
			loadCustomerSchedules();
		}).catch(de);
	}

	const onCloseDeliveryInfoMenu = (menu) => {
		setDeliveryInfoMenu(null);
		setDeliveryType(menu);
	}

	const onCloseDeliveryInfoSubMenu = (menu) => {
		setDeliverySubInfoMenu(null);
		console.warn(menu);
		setDeliveryTypeSub(menu);
	}

	const getDeliverySubMenu = (name) => {
		for (let index = 0; index < deliverySchedule.length; index++) {
			const {
				name: lookupName
			} = deliverySchedule[index];
			if (lookupName == name) {
				return deliverySchedule[index];
			}
		}
		return [];
	}

	const onDeliveryInfoSave = () => {
		var period = deliveryType.name;
		var interval = deliveryTypeSub;

		if (deliveryType.type == 'date') {
			interval = moment(deliveryMonthlyDate).format('DD');
		}
		if (_.isEmpty(interval)) {
			for (let ii = 0; ii < deliverySchedule.length; ii++) {
				const {name, options} = deliverySchedule[ii];
				if (period == name && options.length > 0) {
					interval = options[0].cname;
					break;
				}
			}
		}
		const formdata = new FormData();
		formdata.append('cust_code', currentCustomer.cust_code);
		formdata.append('delivery_period', period);
		formdata.append('delivery_interval', interval);

		API_Service.post(req_cust_delivery, formdata, axiosConfig).then(({data}) => {
			const {extraData, result, message} = data;
			if (result) {
				setDeliveryData(extraData);
			} else {
				onSnack({msg: message, type: 'error'});
			}
		});
	}

	const onDeleteDeliveryInfo = (param) => {
		const {dataIndex} = param;
		const {cust_code} = currentCustomer;
		const {id} = deliveryData[dataIndex];
		const params = axiosURIencode({
			cust_code,
			id
		});
		API_Service.put(req_cust_delivery, params, axiosPut).then(({data}) => {
			const {result, extraData, message} = data;
			if (result) {
				setDeliveryData(extraData || []);
			} else {
				onSnack({msg: message, type: 'error'});
				// loadDeliveryInfo();
			}
		});
	}

	const onChangeItemType = (index, type_code, checked) => {
		setChangingItemType(index);
		const {cust_code} = currentCustomer;
		const param = axiosURIencode({
			cust_code,
			type_code
		});
		API_Service.put(req_cust_itemtype, param, axiosPut).then(({data}) => {
			const {result} = data;
			if (result) {
				// loadCustomerItemType();
			}
			setChangingItemType(-1);
			de(data);
		}).catch(de);
	}

	const onDeleteItemType = (index, type_code) => {
		setChangingItemType(index);
		setItemTypeDtl([]);
		setTypeCode('');
		const param = axiosURIencode({
			type_code
		});
		API_Service.put(req_stock_itemtype, param, axiosPut).then(({data}) => {
			setChangingItemType(-1);
			// loadStockItemType();
			de(data);
		}).catch(de);
	}

	const onSearchItemType = (text) => {
		setSearchItemType({});
		const final = {};
		for (const type_code in itemType) {
			if (Object.hasOwnProperty.call(itemType, type_code)) {
				const {product_type} = itemType[type_code];
				const {type_name} = product_type;
				if (
					JString(type_code).searchTokens(String(text).split(' '), true) ||
					JString(type_name).searchTokens(String(text).split(' '), true)
				) {
					final[type_code] = itemType[type_code];
				}
			}
		}
		setSearchItemType(final);
	}

	const itemListViewClose = (newValue) => {
		const {product_code: old_product_code, type_code} = showItemList;
		if (newValue) {
			const {value} = newValue;
			if (value != reset) {
				const param = axiosURIencode(
					{
						old_product_code, product_code: value, type_code
					}
				);
				API_Service.put(req_stock_itemtypedtl, param, axiosPut).then(({data}) => {
					const {result} = data;
					if (result) {
						setItemTypeDtl([]);
						// loadStockItemType();
						setTypeCode('');
					}
					de(data);
				}).catch(de);
			}
		}
		setShowItemList({});
	};

	const onDeleteItemTypeDtl = (product_code, type_code) => {
		const param = axiosURIencode(
			{
				product_code,
				type_code,
				delete: 1
			}
		);
		API_Service.put(req_stock_itemtypedtl, param, axiosPut).then(({data}) => {
			const {result} = data;
			if (result) {
				setItemTypeDtl([]);
				// loadStockItemType();
				setTypeCode('');
			}
		}).catch(de);
		setShowItemList({});
	};

	const onSaveItemType = () => {
		const formdata = new FormData();
		formdata.append('type_name', newItemType);
		API_Service.post(req_stock_itemtype, formdata, axiosConfig).then(({data}) => {
			const {result, message} = data;
			if (result) {
				// loadStockItemType();
				setNewItemType('');
				setTypeCode('');
			}
			onSnack && onSnack({msg: message, type: result ? 'success' : 'error'})
		}).catch(de);
	}

	const onSaveNewDtlItem = (newValue) => {
		setShowNewDtl(false);
		if (newValue) {
			const {value} = newValue;
			if (value != reset) {
				const formdata = new FormData();
				formdata.append('type_code', typeCode);
				formdata.append('product_code', value);
				API_Service.post(req_stock_itemtypedtl, formdata, axiosConfig).then(({data}) => {
					const {result, message} = data;
					if (result) {
						// loadStockItemType();
						setItemTypeDtl([]);
					}
					onSnack && onSnack({msg: message, type: result ? 'success' : 'error'});
				}).catch(de);
			}
		}
	}

	const onChangeCaseName = props => event => {
		var _length = lengthLimit[`${props}`];
		var text = event.target.value;
		if (text.length > _length) {
			setCaseName(text.slice(0, _length));
		} else {
			setCaseName(text);
		}
	}

	const onChangeCaseDesc = props => event => {
		var _length = lengthLimit[`${props}`];
		var text = event.target.value;
		if (text.length > _length) {
			setCaseDesc(text.slice(0, _length));
		} else {
			setCaseDesc(text);
		}
	}

	const onChangeCaseId = props => event => {
		var _length = lengthLimit[`${props}`];
		var text = event.target.value;
		if (text.length > _length) {
			setCaseId(text.slice(0, _length));
		} else {
			setCaseId(text);
		}
	}
	const loadActiveCoord = () => {
		setActiveCoord([]);
		API_Service.get(`${req_coord}?active_status=1&c_cust_code=${currentCustomer.cust_code}`, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				setActiveCoord(extraData);
			}
		});
	}
	const onSaveCoord = () => {
		setUpdatingCoord(true);
		const formdata = new FormData();
		formdata.append('c_cust_code', currentCustomer.cust_code);
		formdata.append('c_case_id', caseId);
		formdata.append('c_case_name', caseName);
		formdata.append('c_case_desc', caseDesc);

		API_Service.post(req_coord, formdata, axiosConfig).then(({data}) => {
			const {result} = data;
			if (result) {
				onSnack(language.operation_success);
				loadActiveCoord();
			} else {
				onSnack(language.operation_fail, 'error');
			}
			setUpdatingCoord(false);
		});
	}

	const updateCoord = () => {
		var obj = {};
		for (let i = 0; i < activeCoord.length; i++) {
			if (editingCoordIdx == i) {
				obj = activeCoord[i];
				break;
			}
		}
		const eParam = axiosURIencode(
			{
				c_case_name: caseName,
				c_case_desc: caseDesc,
				id: obj.id
			}
		);
		API_Service.put(req_coord, eParam, axiosPut).then(({data}) => {
			const {result} = data;
			if (result) {
				setCaseId('');
				setCaseName('');
				setCaseDesc('');
				setEditingCoordIdx(-1);
				loadActiveCoord();
			} else {
				onSnack(language.operation_fail, 'error');
			}
		});
	}

	const onEditCoord = (index, _delete = false) => {
		setEditingCoordIdx(index);
		var obj = {};
		for (let i = 0; i < activeCoord.length; i++) {
			if (index == i) {
				obj = activeCoord[i];
				break;
			}
		}
		if (_delete) {
			const dParam = {
				active_status: 0,
				id: obj.id
			};
			API_Service.put(req_coord, axiosURIencode(dParam), axiosPut).then(({data}) => {
				const {result} = data;
				if (result) {
					loadActiveCoord();
				} else {
					onSnack(language.operation_fail, 'error');
				}
				setEditingCoordIdx(-1);
			});
		} else {
			setCaseId(obj.c_case_id);
			setCaseName(obj.c_case_name);
			setCaseDesc(obj.c_case_desc);
		}
	}

	const onClickSched = (data) => {
		//onSchedUpdate
		setExistingSchedId(data[0]);
		setSchedAgent(data[1]);
		const prevDate = moment(data[3], 'DD/MM/YYYY HH:mm A').toDate();
		setSchedDate(prevDate);
		setSchedNote(data[4]);
	}

	const getAgentIndex = () => {
		if (parseInt(schedAgent) >= 0) {
			for (let i = 0; i < schedAgentList.length; i++) {
				const {key} = schedAgentList[i];
				if (key == schedAgent) {
					return i;
				}
			}
		}
		return 0;
	}

	const openRoom = () => {
		var _cust_code = encodeURIComponent(currentCustomer.cust_code);
		window.open(`https://localhost:3000/ideamaker/${_cust_code}/-onesignal-/Admin`, '_blank');
	}

	const onChangeSegmentAttachment = (index) => {
		setSegmentAttachment(index);
	}
	const getSegmentAttachment = (index) => {
		return index == segmentAttachment ? 'contained' : 'primary';
	}

	const onImageUpload = (files) => {

		if (files.length > 0) {
			const reader = new FileReader()

			reader.onabort = () => de('file reading was aborted')
			reader.onerror = () => de('file reading has failed')
			reader.onload = () => {
				const binaryStr = reader.result
				setImageUploadContent(itemPdf => (binaryStr));
			}
			reader.readAsArrayBuffer(files[0])
		}
	}

	const onPdfUpload = (files) => {

		if (files.length > 0) {
			const reader = new FileReader()

			reader.onabort = () => de('file reading was aborted')
			reader.onerror = () => de('file reading has failed')
			reader.onload = () => {
				const binaryStr = reader.result
				setPdfUploadContent(itemPdf => (binaryStr));
			}
			reader.readAsArrayBuffer(files[0])
		}
	}

	const onUpdateCustomerOrderType = (obj) => {
		var arr = [];
		for (const key in obj) {
			if (Object.hasOwnProperty.call(obj, key)) {
				const value = obj[key];
				if (value) {
					arr.push(key);
				}
			}
		}
		API_Service.put(req_cust, axiosURIencode({
			order_type: JSON.stringify(arr),
			cust_code: currentCustomer.cust_code
		}), axiosPut).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				onSnack && onSnack({msg: language.record_updated, type: 'success'});
			} else {
				onSnack && onSnack({msg: language.operation_fail, type: 'error'});
			}
		});
	}
	const updateCustomerOrderType = useCallback(_.debounce(onUpdateCustomerOrderType, 500), [orderType]);
	const onOrderType = (event) => {
		setOrderType(event.target.value);
		updateCustomerOrderType({[event.target.value]: event.target.checked});
	}

	const onPdfClick = (link) => {
		link && window.open(link, '_blank');
	}

	const onPressDelete = (master, type, code = '') => {
		var obj = {};
		if (type === 'member') {
			for (const key in customerGroups) {
				if (Object.hasOwnProperty.call(customerGroups, key)) {
					var value = customerGroups[key];
					if (key == master) {
						value = value.filter((item) => item != code);
						customerGroups[key] = value;
					}
				}
			}
			obj = customerGroups;
		}
		if (type === 'leader') {
			for (const key in customerGroups) {
				if (Object.hasOwnProperty.call(customerGroups, key)) {
					const value = customerGroups[key];
					if (key != master) {
						obj[key] = value;
					}
				}
			}
		}
		API_Service.put(req_module_setting, axiosURIencode({
			config: 'customer_groups',
			data: JSON.stringify(obj)
		}), axiosPut).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				loadCustomerGroups();
				onSnack && onSnack({msg: language.record_updated, type: 'success'});
			} else {
				onSnack && onSnack({msg: language.operation_fail, type: 'error'});
			}
		});
	}

	const manageGroup = ({
							 isLeader = false,
							 isMember = false,
							 getLeader = false,
							 getMembers = false
						 }) => {
		var check_isLeader = false;
		var check_isMember = false;
		var leader = '';
		var memberlist = [];
		for (const key in customerGroups) {
			if (Object.hasOwnProperty.call(customerGroups, key)) {
				const arr = customerGroups[key];
				if (key == updatedInfo.cust_code) {
					check_isLeader = true;
					leader = key;
				}
				for (let i = 0; i < arr.length; i++) {
					const _cust_code = arr[i];
					if (_cust_code == updatedInfo.cust_code) {
						check_isMember = true;
						leader = key;
					}
				}
				if ((check_isLeader || check_isMember) && key == leader) {
					memberlist = arr;
				}
			}
		}
		const in_group = check_isLeader || check_isMember;
		if (getLeader && in_group) {
			return leader;
		}
		if (getMembers && in_group) {
			return memberlist;
		}
		if (isLeader && in_group) {
			return check_isLeader;
		}
		if (isMember && in_group) {
			return check_isMember;
		}
	}

	const orderTypesConfig = config?.orderType || [];
	const deliveryInfoSub = getDeliverySubMenu(deliveryType.name) || [];

	const desiredItemTypeObj = _.isEmpty(searchItemType) ? itemType : searchItemType;

	const editCustDetials = (data) => {
		setEditDialog(false);
		if (data.length > 0) {
			onUpdateCustomer(data);
			var address = JString(data[0]['billing_address1']).toStr() +
				JString(data[0]['billing_address2']).toStr() +
				JString(data[0]['billing_address3']).toStr() +
				JString(data[0]['billing_address4']).toStr() +
				JString(data[0]['billing_city']).toStr() +
				JString(data[0]['billing_state']).toStr() +
				JString(data[0]['billing_zipcode']).toStr() +
				JString(data[0]['billing_country']).toStr();
			data[0]['billing_address'] = address;
			currentCustomer = data[0];
			setCurrentCustomer(()=>data[0]);
		}
	}

	return (
		<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
						<CloseIcon/>
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{language.cust_dtl}
					</Typography>
					{
						integration === '0' && currentPage === 0 && (
							<Button
								onClick={() => setEditDialog(true)}
								size="small">
								<EditIcon style={{color: 'black'}}/>
							</Button>
						)
					}
				</Toolbar>
			</AppBar>
			{
				currentPage == 0 && (
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={4}>
							<Paper className={classes.paper}>
								{
									Object.keys(labels).map((key, idx) => {
										var subHeader = labels[key];
										var listValue = '-';
										if (customer === undefined) {
											return null;
										}
										Object.keys(customer).map((nKey, nIdx) => {
											if (nKey === key && customer[nKey]) {
												listValue = customer[nKey];
											}
										});
										if (key === 'current_balance') {
											listValue = JString(listValue).inCurrency();
										}
										if (key === 'created_date') {
											listValue = moment(listValue, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
										}
										if (key === 'company' && listValue !== '-') {
											try {
												const branch = JSON.parse(listValue.toUpperCase());
												listValue = branch.join(", ");
											} catch (e) {
												de("=====Customer key data invalid=====", e);
											}
										}
										return (
											<div
												style={{marginTop: 10}}
												key={String(idx)}>
												<Typography variant={'inherit'} component={'p'}>
													{subHeader}
												</Typography>
												<Typography variant={'inherit'} component={'h4'}>
													{listValue}
												</Typography>
											</div>
										)
									})
								}
							</Paper>
						</Grid>
						{/* <Grid item xs={8}>
                        {
                            branches.length === 0 && noCustBranch === false ?
                            <ShimmerLoading
                                blocks={[
                                    {count:1,height:50},
                                    {count:1,height:40},
                                    {count:1,height:30},
                                    {count:1,height:20},
                                    {count:1,height:40},
                                    {count:1,height:50}
                                ]}
                            />:
                            <MUIDataTable
                                title={language.branches}
                                data={branches}
                                columns={headCells}
                                options={{
                                    print:false,
                                    filter:false,
                                    download:false,
                                    viewColumns:false,
                                    selectableRows:'none',
                                    rowsPerPage : 5,
                                    rowsPerPageOptions : [5,10,15],
                                    responsive: 'scrollMaxHeight',
                                    onRowClick:(rowData, rowMeta)=>{  },
                                    setTableProps:()=>({size:'small'})
                                }}
                            />
                        }
                        </Grid> */}
						<div className={classes.grid}>
							{
								(signedStatements && signedStatements.length > 0) && (
									<Typography className={classes.grid} variant="subtitle1" component="h2"
												color="textSecondary">
										{language.signed_statements}
									</Typography>
								)
							}
						</div>
					</Grid>
				)
			}
			{
				currentPage == 0 && (
					<Grid container spacing={3} className={classes.grid}>
						<div className={rowClass.viewInRow}>
							{
								signedStatements && signedStatements.map((item, index) => {
									return (
										<Card
											onClick={() => {
												window.open(item.statement_path, "_blank")
											}}
											className={classes.card}
											key={String(index)}>
											<CardActionArea>
												<CardContent>
													<DescriptionIcon/>
													<Typography variant="subtitle1" component="p">
														{item.title}
													</Typography>
													<Typography color={'textSecondary'} variant={"subtitle2"} component="p">
														{moment(item.updated_at, 'YYYY-MM-DD HH:mm:ss').fromNow()}
													</Typography>
												</CardContent>
											</CardActionArea>
										</Card>
									)
								})
							}
							{
								(signedStatements && signedStatements.length > 0) && (
									<Tooltip title={"Refresh data"}>
										<IconButton onClick={() => loadCustomerSignedStatements()}>
											<SyncIcon/>
										</IconButton>
									</Tooltip>
								)
							}
						</div>
					</Grid>
				)
			}
			{
				currentPage == 30000 && (
					<>
						<div style={{height: 20}}/>
						<Paper className={classes.schedAddSection}>
							<Button
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick={e => setDeliveryInfoMenu(e.currentTarget)}
								size="small"
								endIcon={<KeyboardArrowDownRounded/>}
								startIcon={<DescriptionIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{deliveryType.name ? deliveryType.name : deliverySchedule[0]?.name}
								</Typography>
							</Button>
							<Menu
								id="simple-menu-del-info"
								anchorEl={deliveryInfoMenu}
								keepMounted
								open={Boolean(deliveryInfoMenu)}
								onClose={() => onCloseDeliveryInfoMenu(deliveryType)}>
								{
									deliverySchedule.map((item, idx) =>
										<MenuItem key={String(idx)} onClick={() => onCloseDeliveryInfoMenu(item)}>
											{item.name}
										</MenuItem>
									)
								}
							</Menu>
							<div style={{width: 20}}/>
							{
								(deliveryInfoSub?.type === 'date') ?
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DatePicker
											views={['year', 'date']}
											label={language.expected_del_date}
											disablePast
											inputProps={{
												style: {fontSize: 12}
											}}
											inputVariant={'outlined'}
											value={deliveryMonthlyDate}
											onChange={setDeliveryMonthlyDate}/>
									</MuiPickersUtilsProvider>
									:
									<Button
										disabled={deliveryInfoSub?.options?.length === 0}
										className={commonStyle.searchBtnSize}
										color="inherit"
										onClick={e => setDeliverySubInfoMenu(e.currentTarget)}
										size="small"
										endIcon={<KeyboardArrowDownRounded/>}
										startIcon={<DescriptionIcon/>}>
										<Typography component="p" className={commonStyle.searchBtnFont}>
											{deliveryTypeSub ? deliveryTypeSub : deliveryInfoSub?.options?.length ?
												deliveryInfoSub.options[0].cname :
												language.show_details
											}
										</Typography>
									</Button>
							}
							{
								(deliveryInfoSub?.type === 'date') ? null :
									<Menu
										id="simple-menu-del-sub-info"
										anchorEl={deliverySubInfoMenu}
										keepMounted
										open={Boolean(deliverySubInfoMenu)}
										onClose={() => onCloseDeliveryInfoSubMenu(deliveryTypeSub)}>
										{
											deliveryInfoSub?.options?.map((item, idx) =>
												<MenuItem key={String(idx)}
														  onClick={() => onCloseDeliveryInfoSubMenu(item.cname)}>
													{item.cname}
												</MenuItem>
											)
										}
									</Menu>
							}
							<div style={{width: 20}}/>
							<Button
								onClick={() => {
									onDeliveryInfoSave()
								}}
								size={'large'}
								color={'primary'}>
								{language.save || ''}
							</Button>
						</Paper>
						<Grid container spacing={3} className={classes.grid}>
							<Grid item xs={12}>
								<MUIDataTable
									data={_.isEmpty(deliveryData) ? [] : deliveryData}
									columns={deliveryHeadCell}
									options={{
										print: false,
										filter: false,
										download: false,
										viewColumns: false,
										rowsPerPage: 30,
										rowsPerPageOptions: [30, 45, 65],
										responsive: 'scrollMaxHeight',
										onRowClick: (rowData, rowMeta) => {
										},
										onRowsDelete: ({data, lookup}) => {
											onDeleteDeliveryInfo(data[0]);
										},
										setTableProps: () => ({size: 'small'})
									}}
								/>
							</Grid>
						</Grid>
					</>
				)
			}
			{
				currentPage == 10000 && (
					<>
						<div style={{height: 20}}/>
						<Paper className={classes.schedAddSection}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DateTimePicker
									label={language.appointment_time}
									disablePast
									autoOk
									inputProps={{
										style: {fontSize: 12}
									}}
									inputVariant={'outlined'}
									value={schedDate}
									onChange={setSchedDate}/>
							</MuiPickersUtilsProvider>
							<div style={{width: 20}}/>
							<AutoCompleteSearch
								width={'20%'}
								language={language}
								preData={schedAgentList}
								onFocus={() => {
								}}
								title={language.search_agent}
								onItemSelect={(v) => setSchedAgent(v.key)}
								value={schedAgentList[getAgentIndex()]}
							/>
							<div style={{width: 20}}/>
							<TextField
								label={language.note_agent}
								variant={parseInt(existingSchedId) > 0 ? 'filled' : 'standard'}
								className={classes.schedNote}
								onChange={e => {
									setSchedNote(e.target.value)
								}}
							/>
							<div style={{width: 20}}/>
							<Button
								onClick={() => {
									parseInt(existingSchedId) > 0 ? onSchedUpdate() : onSchedPost()
								}}
								size={'large'}
								color={'primary'}>
								{(parseInt(existingSchedId) > 0 ? language.update : language.save) || ''}
							</Button>
						</Paper>
						<Grid container spacing={3} className={classes.grid}>
							<Grid item xs={12}>
								<MUIDataTable
									data={schedList}
									columns={schedHeadCell}
									options={{
										print: false,
										filter: false,
										download: false,
										viewColumns: false,
										rowsPerPage: 30,
										rowsPerPageOptions: [30, 45, 65],
										responsive: 'scrollMaxHeight',
										onRowClick: (rowData, rowMeta) => {
											onClickSched(rowData)
										},
										onRowsDelete: ({data, lookup}) => {
											onDeleteSched(data);
										},
										setTableProps: () => ({size: 'small'})
									}}
								/>
							</Grid>
						</Grid>
					</>
				)
			}
			{
				currentPage == 1 && (
					<>
						<div style={{height: 20}}/>
						<div className={rowClass.viewInRow}>
							{
								availableAgents && availableAgents.map((item, index) => {
									const preferred_agent = Array.isArray(agentList) ? agentList.filter((n) => n.staff_code == item.staff_code) : [];
									const chosen = preferred_agent.length > 0;
									return (
										<Card
											onClick={() => {
												if (agentListLoading == 0) {
													toggleAgent(item.login_id, (+(!chosen)), item.company)
												}
											}}
											disabled={agentListLoading == item.login_id}
											className={chosen ? classes.cardChosen : classes.card}
											key={String(index)}>
											<CardActionArea>
												<CardContent>
													<div style={{display: "flex"}}>
														{
															agentListLoading == item.login_id ?
																<CircularProgress size={20}/> :
																<PersonIcon/>
														}
														{
															chosen ? <Done style={{marginLeft: 'auto'}}/> : ''
														}
													</div>
													<Typography variant="subtitle1" component="p">
														{item.name}
													</Typography>
													<Typography variant={"subtitle2"} component="p">
														{item.staff_code} | {item?.company && (
															<>{item.company.toUpperCase()}</>
														)}
													</Typography>
												</CardContent>
											</CardActionArea>
										</Card>
									)
								})
							}
						</div>
					</>
				)
			}
			{
				currentPage == 40000 && (
					<>
						<Grid container spacing={3} className={classes.grid}>
							<Grid item xs={6}>
								<Paper className={classes.paperList}>
									<div className={classes.viewInRowSpace}>
										<TextField
											className={classes.newType}
											label={language.create_type}
											margin={'dense'}
											variant={'standard'}
											value={newItemType}
											onChange={e => setNewItemType(e.target.value)}
										/>
										<Button
											disabled={_.isEmpty(newItemType)}
											onClick={() => onSaveItemType()}
											color={'primary'}
											variant={'text'}>
											<Typography
												component={'p'}
												variant={'h6'}
												style={{
													color: _.isEmpty(newItemType) ? grey[500] : blue[500],
													fontSize: 13
												}}>
												{language.save}
											</Typography>
										</Button>
									</div>
									<SearchBox
										text={language.search_item_type}
										onChangeText={(text) => {
											onSearchItemType(text)
										}}
									/>
									<List>
										{
											Object.keys(desiredItemTypeObj).map((type_code, index) => {
												const {product_type, product_type_dtl} = desiredItemTypeObj[type_code];
												const {type_name} = product_type;
												const labelId = type_code + '--labelId';
												return (
													<ListItem
														key={index + '--tl'}
														role={undefined}
														dense
														button>
														<ListItemIcon>
															{
																changingItemType == index ?
																	<CircularProgress size={20}/> :
																	<Checkbox
																		onChange={(event) => onChangeItemType(index, type_code, event.target.checked)}
																		edge="start"
																		checked={custItemType && custItemType.includes(type_code)}
																		tabIndex={-1}
																		disableRipple
																		color={'primary'}
																		inputProps={{'aria-labelledby': labelId}}
																	/>
															}
														</ListItemIcon>
														<ListItemText
															id={labelId}
															primary={type_name}
															secondary={
																<Fragment>
																	<Typography
																		component="span"
																		variant="body2"
																		color="textSecondary">
																		{type_code}
																	</Typography>
																</Fragment>
															}
														/>
														<ListItemSecondaryAction>
															{
																changingItemType == index ?
																	<CircularProgress size={20}/> :
																	<IconButton
																		onClick={() => {
																			onDeleteItemType(index, type_code)
																		}}
																		edge="end"
																		aria-label="comments">
																		<DeleteIcon htmlColor={red[500]}/>
																	</IconButton>
															}
															<IconButton
																onClick={() => {
																	setItemTypeDtl(product_type_dtl);
																	setHighlightRow(index);
																	setTypeCode(type_code);
																}}
																edge="end"
																aria-label="comments">
																<ChevronRightIcon
																	color={index == highlightRow ? 'primary' : 'secondary'}
																/>
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												)
											})
										}
									</List>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								{
									!_.isEmpty(typeCode) && (
										<div className={classes.viewInRowSpace}>
											<Button
												onClick={() => setShowNewDtl(true)}
												variant={'contained'}
												color={'primary'}
												className={classes.flexBtn}>
												<Typography
													component={'p'}
													variant={'h6'}
													style={{
														color: 'white',
														fontSize: 13
													}}>
													{language.add_item}
												</Typography>
											</Button>
										</div>
									)
								}
								{
									itemTypeDtl?.length > 0 && (
										<List style={{marginBottom: 100}}>
											{
												itemTypeDtl.map((item, index) =>
													<ListItem key={index + '--tld'} role={undefined} dense button>
														<ListItemText
															id={index + '-x-labelId'}
															primary={
																item.product_name ||
																<Typography
																	variant={'subtitle2'}
																	style={{color: red[500]}}>
																	{language.item_not_exist}
																</Typography>
															}
															secondary={
																<Fragment>
																	<Typography
																		component="span"
																		variant="body2"
																		color="textSecondary">
																		{item.product_code}
																	</Typography>
																</Fragment>
															}
														/>
														<ListItemSecondaryAction>
															<IconButton
																onClick={() => {
																	onDeleteItemTypeDtl(item.product_code, item.type_code);
																}}
																edge="end"
																aria-label="comments">
																<DeleteIcon htmlColor={red[500]}/>
															</IconButton>
															<IconButton
																onClick={() => {
																	setShowItemList(item)
																}}
																edge="end"
																aria-label="comments">
																<SwapHorizIcon/>
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												)
											}
										</List>
									)
								}
							</Grid>
						</Grid>
					</>
				)
			}
			{
				currentPage == 10000 && (
					<Grid container spacing={1} className={classes.grid}>
						<Grid item xs={6}>
							<Paper className={classes.coordRoot}>
								<TextField
									InputProps={{
										endAdornment: <CircularProgressWithLabel
											key={'name'}
											value={caseName.length}
											targetValue={lengthLimit[`case_name`]}/>
									}}
									onChange={onChangeCaseName('case_name')}
									id="case-name"
									value={caseName}
									label={`${language.room_name}`}/>
								<TextField
									InputProps={{
										endAdornment: <CircularProgressWithLabel
											key={'name'}
											value={caseDesc.length}
											targetValue={lengthLimit[`case_desc`]}/>
									}}
									onChange={onChangeCaseDesc('case_desc')}
									id="case-desc"
									value={caseDesc}
									label={`${language.room_desc}`}/>
								<TextField
									InputProps={{
										endAdornment: <CircularProgressWithLabel
											key={'name'}
											value={caseId.length}
											targetValue={lengthLimit[`case_id`]}/>
									}}
									onChange={onChangeCaseId('case_id')}
									id="case-id"
									value={caseId}
									label={`${language.room_caseid}`}/>
								{
									updatingCoord ?
										<CircularProgress size={20}/> :
										<Button
											disabled={_.isEmpty(caseName)}
											onClick={() => {
												editingCoordIdx == -1 ? onSaveCoord() : updateCoord()
											}}
											color={'primary'}
											size={'medium'}
											variant={'text'}>
											<Typography
												component={'p'}
												variant={'h6'}
												style={{
													color: _.isEmpty(caseName) ? grey[500] : blue[500],
													fontSize: 13
												}}>
												{language.save}
											</Typography>
										</Button>
								}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.paperList}>
								{
									activeCoord?.length && activeCoord.length > 0 ?
										<Button
											onClick={() => openRoom()}
											fullWidth
											variant={'contained'}
											color={'primary'}
											size={'small'}>
											<Typography
												component={'p'}
												variant={'h6'}
												style={{
													color: 'white',
													fontSize: 13
												}}>
												{language.open_room}
											</Typography>
										</Button>
										: null
								}
								<List>
									{
										activeCoord?.map && activeCoord.map((obj, index) => {
											const {c_case_name, c_case_desc, c_case_id} = obj;
											const labelId = obj.id + '--labelId';
											return (
												<ListItem
													key={index + '--tl'}
													role={undefined}
													dense
													button>
													<ListItemText
														id={labelId}
														primary={c_case_name}
														secondary={
															<Fragment>
																<Typography
																	component="span"
																	variant="body2"
																	color="textSecondary">
																	{c_case_desc}
																</Typography>
															</Fragment>
														}
													/>
													<ListItemSecondaryAction>
														<Chip size="small" label={c_case_id}/>
														{
															editingCoordIdx == index ?
																<CircularProgress size={20}/> :
																<IconButton
																	onClick={() => {
																		onEditCoord(index, true)
																	}}
																	edge="end"
																	aria-label="comments">
																	<DeleteIcon htmlColor={red[500]}/>
																</IconButton>
														}
														{
															editingCoordIdx == index ?
																<CircularProgress size={20}/> :
																<IconButton
																	onClick={() => {
																		onEditCoord(index)
																	}}
																	edge="end"
																	aria-label="comments">
																	<EditIcon
																		color={index == highlightRow ? 'primary' : 'secondary'}
																	/>
																</IconButton>
														}
													</ListItemSecondaryAction>
												</ListItem>
											)
										})
									}
								</List>
							</Paper>
						</Grid>
					</Grid>
				)
			}
			{
				currentPage == 2 && (
					<>
						<div style={{height: 20}}/>
						<Grid container spacing={3} className={classes.grid}>
							<Grid item xs={6}>
								<Paper className={classes.paperCenter}>
									{
										showDropzoneImage ?
											<DropzoneArea
												onChange={(files) => {
													onImageUpload(files)
												}}
												dropzoneParagraphClass={classes.dropzoneText}
												acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
												filesLimit={1}
												clearOnUnmount={true}
											/> : <div style={{height: 250}}/>
									}
									<Button
										onClick={() => onAttachmentPost('image')}
										disabled={_.isNull(imageUploadContent)}
										disableElevation={true}
										size="small"
										color={'primary'}>
										{language.save || ''}
									</Button>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper className={classes.paperCenter}>
									{
										showDropzonePdf ?
											<DropzoneArea
												dropzoneText={'Drag and drop a PDF file or click'}
												onChange={(files) => {
													onPdfUpload(files)
												}}
												dropzoneParagraphClass={classes.dropzoneText}
												acceptedFiles={['.pdf']}
												filesLimit={1}
												clearOnUnmount={true}
											/> : <div style={{height: 250}}/>
									}
									<Button
										onClick={() => onAttachmentPost('pdf')}
										disabled={_.isNull(pdfUploadContent)}
										disableElevation={true}
										size="small"
										color={'primary'}>
										{language.save || ''}
									</Button>
								</Paper>
							</Grid>
						</Grid>
						{
							(!_.isEmpty(attachments?.image) || !_.isEmpty(attachments?.pdf)) && (
								<Grid container spacing={0} className={classes.grid}>
									<Grid item xs={12}>
										<ButtonGroup color="primary">
											<Button variant={getSegmentAttachment(0)}
													onClick={() => onChangeSegmentAttachment(0)}>
												Image
											</Button>
											<Button variant={getSegmentAttachment(1)}
													onClick={() => onChangeSegmentAttachment(1)}>
												PDF
											</Button>
										</ButtonGroup>
										<div style={{height: 20}}/>
										<div className={rowClass.viewInRow}>
											{
												segmentAttachment == 0 && !_.isEmpty(attachments?.image) && attachments?.image.map((item, index) => {
													return (
														<MediaBox
															primaryBtn={language.view}
															secondaryBtn={language.delete}
															primaryBtnAction={() => {
																setViewImage(item.link)
															}}
															secondaryBtnAction={() => {
																onDeleteAction(item.id, 'image')
															}}
															image={item.link}
															description={moment(item.created_date).format('DD/MM/YYYY hh:mmA')}
														/>
													)
												})
											}
											{
												segmentAttachment == 1 && !_.isEmpty(attachments?.pdf) && attachments?.pdf.map((item, index) => {
													return (
														<MediaBox
															primaryBtn={language.view}
															secondaryBtn={language.delete}
															primaryBtnAction={() => {
																onPdfClick(item.link)
															}}
															secondaryBtnAction={() => {
																onDeleteAction(item.id, 'pdf')
															}}
															image={pdf_image}
															description={moment(item.created_date).format('DD/MM/YYYY hh:mmA')}
														/>
													)
												})
											}
										</div>
									</Grid>
								</Grid>
							)
						}
					</>
				)
			}
			{
				currentPage == 3 && (
					<>
						<div style={{height: 20}}/>
						<MUIDataTable
							title={language.template_list}
							data={stockTemplate}
							columns={stockHead}
							options={{
								print:false,
								filter:false,
								download:false,
								viewColumns:true,
								selectableRows:'none',
								rowsPerPage : 5,
								rowsPerPageOptions : [5,10,15],
								responsive: 'scrollMaxHeight',
								onRowClick:(rowData, rowMeta)=>{},
								setTableProps:()=>({size:'small'})
							}}
						/>
					</>
				)
			}
			<VirtualList
				open={!_.isEmpty(showItemList)}
				setClose={data => itemListViewClose(data)}
				title={language.stock}
				data={itemList}
				language={language}
			/>
			<VirtualList
				open={showNewDtl}
				setClose={data => onSaveNewDtlItem(data)}
				title={language.stock}
				data={itemList}
				language={language}
			/>
			<ImageViewer
				open={!_.isEmpty(viewImage)}
				src={viewImage}
				onDismiss={() => {
					setViewImage('')
				}}
				imageStyle={classes.largeImage}
			/>
			<Alert
				setPositive={() => {
					setAttachIdToDelete({})
				}}
				setNegative={() => {
					onAttachmentDelete()
				}}
				setClose={() => {
					setAttachIdToDelete({})
				}}
				open={!_.isEmpty(attachIdToDelete?.type)}
				title={language.es_backend}
				message={language.atch_delete}
				positive={language.cancel}
				negative={language.proceed_caps}
				autoDispossal={true}
				unmount={true}
			/>
			<AddCustomer
				open={editDialog}
				onClose={(data) => editCustDetials(data)}
				language={language}
				currentCustomer={customer}
				custCategoryList={custCategoryList}
				custChainList={custChainList}
				custTypeList={custTypeList}
				branchesList={branchesList}
			/>
			<div style={{height: 250}}/>
			<BottomNavigation
				value={currentPage}
				onChange={(event, newValue) => {
					setCurrentPage(newValue);
				}}
				showLabels
				className={classes.bottomNav}>
				<BottomNavigationAction label="Info" icon={<InfoIcon/>}/>
				{/* <BottomNavigationAction label="CheckIn Schedule" icon={<ScheduleIcon />} /> */}
				<BottomNavigationAction label="Agents" icon={<PersonOutlineIcon/>}/>
				{/* <BottomNavigationAction label="Delivery" icon={<LocalShippingIcon />} /> */}
				{/* <BottomNavigationAction label="Item Type" icon={<CollectionsBookmarkIcon />} /> */}
				<BottomNavigationAction label="Attachment" icon={<AttachmentIcon/>}/>
				<BottomNavigationAction label="Template" icon={<NoteAddIcon/>}/>
			</BottomNavigation>
		</Dialog>
	)
}
