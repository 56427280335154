import React, {useContext, useEffect, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Button from '@material-ui/core/Button';
import {
	req_checkin_merchandising,
	req_cust,
	req_cust_area
} from '../../Helper/Constants';
import {usePaperStyles} from '../../Styles/StylePaper';
import {useCommonStyles} from '../../Styles';
import moment from 'moment';
import AppContext from '../../Services/AppContext';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, { axiosURIencode } from '../../Services/API_Service';
import {
    Breadcrumbs,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import _ from 'lodash';
import VirtualList from '../../Components/VirtualList';
import {de} from '../../Helper/Helper';
import AnyDialog from '../../Components/AnyDialog';
import {Autocomplete} from '@material-ui/lab';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import VirtualListCB from '../../Components/VirtualListCB';
import CustomerFullTextSearch from '../../Components/CustomerFullTextSearch';

const makebranchaddress = (obj) => {
	var baddress = '', saddress = '';
	for (const column_name in obj) {
		if (Object.hasOwnProperty.call(obj, column_name)) {
			const column_value = obj[column_name];
			if (JString(column_name).searchTokens(['billing'], true)) {
				baddress += column_value;
			}
			if (JString(column_name).searchTokens(['shipping'], true)) {
				saddress += column_value;
			}
		}
	}
	return {baddress, saddress};
}
const makebranchname = (obj) => {
	return JString(obj.branch_name)
			.replaceInfinity('[\(\)]', '')
			.replaceInfinity(obj.branch_code, '')
			.str.trim()
		||
		'-';
}

export default function AddAppointmentMerchandiser({
													   open,
													   onDismiss,
													   language,
													   merchandiserList,
													   customerList,
													   branchList,
													   axiosGet,
													   onSnack,
													   requestReload,
													   isEditing = false,
													   editData = {
														   appmnt_code: "",
														   appmnt_note: "",
														   appmnt_doc_type: "",
														   appmnt_udf: "",
														   cust_code: "",
														   branch_code: "",
														   salesperson_id: "",
														   start_date: "",
														   end_date: "",
														   repetitive: "",
														   appmnt_interval: "",
														   appmnt_status: "",
														   active_status: ""
													   },
													   date
												   }) {
	const commonStyle = useCommonStyles();
	const paperStyle = usePaperStyles();
	const repeatOptions = [
		{name: 'Every 1 Week', value: '1 Week'},
		{name: 'Every 2 Week', value: '2 Week'},
		{name: 'Every 3 Week', value: '3 Week'},
		{name: 'Every 4 Week', value: '4 Week'}
	];
	const durationOptions = [
		{name: '1 Hour', value: '01:00'},
		{name: '2 Hours', value: '02:00'},
		{name: '3 Hours', value: '03:00'},
		{name: '4 Hours', value: '04:00'}
	];
	/** Merchandising var - Start */
	const [chosenDateFrom, setChosenDateFrom] = useState(new Date());
	const [chosenDueDateFrom, setChosenDueDateFrom] = useState(new Date());
	const [chosenTime, setChosenTime] = useState(new Date());
	const [chosenDuration, setChosenDuration] = useState('');
	const [chosenRepeatSched, setChosenRepeatSched] = useState('');
	const [chosenBranch, setChosenBranch] = useState({});
	const [chosenNote, setChosenNote] = useState('');
	const [customer, setCustomer] = useState({});
	const [branches, setBranches] = useState([]);
	const [multipleCustomers, setMultipleCustomers] = useState([]);
	const [merchandiser, setMerchandiser] = useState('');
	const [asDueDate, setAsDueDate] = useState(false);
	const [enableTime, setEnableTime] = useState(false);
	const [enableDuration, setEnableDuration] = useState(false);
	const [repeatSched, setRepeatSched] = useState(false);
	const [allDaySched, setAllDaySched] = useState(true);
	/** Merchandising var - End */

	const [customerFilterIndex, setCustomerFilterIndex] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [gState, setGState] = useContext(AppContext);
	const [customerView, showCustomerView] = useState(false);
	const [multiCustomerView, showMultiCustomerView] = useState(false);
	const [filterCustomerView, showFilterCustomerView] = useState(false);

	const [csList, setCsList] = useState([]);
	const [filteredCsList, setFilteredCsList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [areaList, setAreaList] = useState([]);

	useEffect(() => {
		if(date){
			setChosenDateFrom(date);
			setChosenDueDateFrom(date);
		}
	}, [open]);

	const reset = () => {
		setChosenDateFrom(new Date());
		setChosenDueDateFrom(new Date());
		setChosenTime(new Date());
		setChosenDuration('');
		setChosenRepeatSched('');
		setChosenBranch({});
		setChosenNote('');
		setCustomer({});
		setMultipleCustomers([]);
		setMerchandiser('');
		setAsDueDate(false);
		setEnableTime(false);
		setEnableDuration(false);
		setRepeatSched(false);
	}
	const onDueDate = event => {
		setAsDueDate(event.target.checked);
	}
	const onEnableTime = event => {
		setEnableTime(event.target.checked);
	}
	const onEnableDuration = event => {
		setEnableDuration(event.target.checked);
	}
	const onCustomerOption = (idx) => {
		if ([1, 2].includes(idx)) {
			setMultipleCustomers([]);
		}
		setCustomerFilterIndex(idx);
	}
	const onCustomerViewClose = (newValue) => {
		showCustomerView(false);
		if (newValue) {
			const {value} = newValue;
			if (branchList[value]) {
				setBranches(branchList[value]);
			}
			setCustomer(newValue);
		}
	}
	const onMultiCustomerViewClose = (arr) => {
		showMultiCustomerView(false);
		if (arr.length > 0) {
			setMultipleCustomers(arr);
		} else {
			setMultipleCustomers([]);
		}
	}
	const onCustomerFilterViewClose = (data) => {
		if (data != null) {
			setMultipleCustomers(data);
		}
		showFilterCustomerView(false);
	}
	const onRepeatSched = event => {
		setRepeatSched(event.target.checked);
	}
	const onAllDaySched = event => {
		setAllDaySched(event.target.checked);
	}
	const onSelectDuration = event => {
		setChosenDuration(event.target.value);
	}
	const onSelectRepeatSched = event => {
		setChosenRepeatSched(event.target.value);
	}
	const onSelectBranch = event => {
		setChosenBranch(event.target.value);
	}
	const getbranch = (id) => {
		for (let i = 0; i < branches.length; i++) {
			const {branch_code} = branches[i];
			if (branch_code == id) {
				return branches[i];
			}
		}
		return {};
	}
	const getbreadcrumbcolor = index => {
		return index === customerFilterIndex ?
			"primary" :
			"textSecondary";
	}
	const customerplaceholder = () => {
		var label = '';
		if (customerFilterIndex === 0 && customer?.name) {
			label = customer?.name;
		}
		if ([1, 2].includes(customerFilterIndex) && multipleCustomers.length > 0) {
			label = `${multipleCustomers.length} customer${multipleCustomers.length > 1 ? 's' : ''} selected`;
		}
		return label ? label : [
			language.single_customer,
			language.multiple_customer,
			language.customer_by_area
		][customerFilterIndex];
	}
	const onCustomerBtnClick = () => {
		customerFilterIndex === 0 && showCustomerView(true);
		customerFilterIndex === 1 && showMultiCustomerView(true);
		customerFilterIndex === 2 && showFilterCustomerView(true);
	}
	const onMerchandOptionSelect = (option, value) => {
		return option.key == value.key;
	}
	const onAreaOptionSelect = (option, value) => {
		return option.key == value.key;
	}
	const getAreaList = () => {
		API_Service.get(req_cust_area, axiosGet).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, value} = extraData[idx];
					_data.push({name: name, secondary: value, value: value, status: 1});
				}
				setAreaList(_data);
			}
		});
	}
	const loadCustomerList = (salesperson, company) => {
		setLoading(true);
		const param = { salesman: salesperson };
		if (company) {
			param.company = company;
		}
		const params = axiosURIencode(param);
		API_Service.get(`${req_cust}?${params}`, axiosGet).then(({data})=>{
			const {result, extraData} = data;
			if (JString(result).toNumber() === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const obj = extraData[idx];
					const { cust_company_name, cust_code, cust_area } = obj;
					_data.push({ name: cust_company_name, secondary: cust_code, value: cust_code, status: 1, area: cust_area });
				}
				setCsList(csList=>(_data));
				setFilteredCsList(filteredCsList=>(_data));
			} else {
				setCsList([]);
				setFilteredCsList([]);
			}
			setLoading(false);
		});
	}

	const updateMechandiser = (v) => {
		setMultipleCustomers([]);
		if (v) {
			setMerchandiser(v.key);
			loadCustomerList(v.key, v.company);
			getAreaList();
		} else {
			setMerchandiser('');
			setCsList([]);
			setFilteredCsList([]);
		}
	}

	const updateArea = (v) => {
		if (v) {
			const filteredArray = csList.filter(item => item.area === v.value);
			setFilteredCsList(filteredArray);
		} else {
			setFilteredCsList(csList);
		}
	}

	const onSave = () => {
		setIsLoading(true);
		var customers = [
			{
				cust_code: customer?.value,
				branch_code: chosenBranch
			}
		];
		if (customerFilterIndex === 1 && multipleCustomers.length === 0) {
			return;
		}
		if (customerFilterIndex === 2 && multipleCustomers.length === 0) {
			return;
		}
		if (customerFilterIndex === 1) {
			customers = [];
			for (let i = 0; i < multipleCustomers.length; i++) {
				const {name, value} = multipleCustomers[i];
				customers.push({
					cust_code: value,
					branch_code: ''
				});
			}
		}
		if (customerFilterIndex === 2) {
			customers = [];
			for (let i = 0; i < multipleCustomers.length; i++) {
				const {cust_code, branch_code} = multipleCustomers[i];
				customers.push({
					cust_code,
					branch_code
				});
			}
		}
		var start_date = moment(chosenDateFrom).format('YYYY-MM-DD');
		var end_date = start_date;
		var end_date = moment(chosenDueDateFrom).format('YYYY-MM-DD');
		// if (repeatSched && asDueDate == false) {
		// 	end_date = moment(chosenDateFrom).add(1, 'year').format('YYYY-MM-DD');
		// }
		// if (asDueDate) {
		// 	start_date = moment().format('YYYY-MM-DD');
		// 	end_date = moment(chosenDateFrom).format('YYYY-MM-DD');
		// }
		var salesperson_id = merchandiser;
		var appmnt_note = chosenNote;
		var repetitive = 0;
		if (repeatSched) {
			repetitive = 1;
		}
		var created_by = gState.username;
		var time = '08:00';
		if (enableTime) {
			time = moment(chosenTime).format('HH:mm');
		}
		var duration = '01:00';
		if (enableDuration) {
			duration = chosenDuration;
			// var hour = 12 - parseInt(moment(chosenDuration).format('hh'));
			// if(hour < 10){
			//     hour += `0${hour}`;
			// }
			// var minute = moment(chosenDuration).format('mm');
			// duration = `${hour}:${minute}`;
		}
		var interval = '';
		if (repeatSched) {
			interval = chosenRepeatSched;
		}
		if (allDaySched) {
			time = '00:00';
			duration = '24:00';
		}
		var objToSend = {
			customers: JSON.stringify(customers),
			start_date,
			end_date,
			salesperson_id,
			appmnt_note,
			repetitive,
			created_by,
			time,
			duration,
			interval
		};
		de(objToSend);
		var formdata = new FormData();
		for (const prop in objToSend) {
			if (Object.hasOwnProperty.call(objToSend, prop)) {
				const value = objToSend[prop];
				formdata.append(prop, value);
			}
		}
		API_Service.post(req_checkin_merchandising, formdata, axiosGet).then(({data}) => {
			console.log(data);
			if (data.result == 1) {
				reset();
				onDismiss();
				requestReload();
			}
			setIsLoading(false);
			onSnack(data.message, data.result == 1 ? 'success' : 'error');
		}).catch(e => {
			onSnack(language.operation_fail, 'error');
			setIsLoading(false);
		});
	}
	const disableSave =
		!JString(merchandiser).isValid() ||
		([1, 2].includes(customerFilterIndex) && multipleCustomers.length === 0) ||
		isLoading;

	return (
		<AnyDialog open={open} onDismiss={() => {
			onDismiss();
			reset();
		}}>
			<div className={paperStyle.dialogPaper}>
				<Breadcrumbs aria-label="breadcrumb">
					{/* <Button variant={'text'} onClick={()=>onCustomerOption(0)}>
                        <Typography
                            component={'p'} className={commonStyle.searchBtnFont}
                            color={getbreadcrumbcolor(0)}>
                            { language.single_customer }
                        </Typography>
                    </Button> */}
					<Button variant={'text'} onClick={() => onCustomerOption(1)}>
						<Typography
							component={'p'} className={commonStyle.searchBtnFont}
							color={getbreadcrumbcolor(1)}>
							{language.multiple_customer}
						</Typography>
					</Button>
					{/* <Button variant={'text'} onClick={() => onCustomerOption(2)}>
						<Typography
							component={'p'} className={commonStyle.searchBtnFont}
							color={getbreadcrumbcolor(2)}>
							{language.customer_by_area}
						</Typography>
					</Button> */}
				</Breadcrumbs>
				<div className={commonStyle.wrapper}>
					<Autocomplete
						fullWidth
						className={commonStyle.input}
						options={merchandiserList}
						getOptionSelected={(option, value) => onMerchandOptionSelect(option, value)}
						getOptionLabel={(option) => option.name}
						onChange={(e, v, r) => updateMechandiser(v)}
						id="merchandiser"
						renderInput={(params) =>
							<TextField
								placeholder={String(language.person_in_charge).toUpperCase()}
								{...params}
								margin="normal"/>
						}
					/>
				</div>
				<div className={commonStyle.wrapper}>
					<Autocomplete
						fullWidth
						className={commonStyle.input}
						options={areaList}
						getOptionSelected={(option, value) => onAreaOptionSelect(option, value)}
						getOptionLabel={(option) => option.name}
						onChange={(e, v, r) => updateArea(v)}
						id="area"
						renderInput={(params) =>
							<TextField
								placeholder={String(language.area).toUpperCase()}
								{...params}
							/>
						}
					/>
				</div>
				<Button
					startIcon={
						loading && [0, 1].includes(customerFilterIndex) ?
							<CircularProgress color={'inherit'} size={20}/> :
							undefined
					}
					disabled={filteredCsList.length === 0}
					onClick={() => onCustomerBtnClick()}
					variant={'contained'}
					fullWidth
					color={'primary'}
					className={commonStyle.button}>
					<Typography component={'p'} className={commonStyle.searchBtnFont}>
						{customerplaceholder()}
					</Typography>
				</Button>
				{
					branches?.length > 0 ?
						<FormControl fullWidth style={{padding: 8, paddingRight: 0}}>
							<InputLabel id="branch-id-label" style={{marginLeft: 7}}>Site/Branch</InputLabel>
							<Select
								fullWidth
								labelId="branch-id-label"
								id="branch-id"
								value={chosenBranch}
								renderValue={(branch_code) => {
									const item = getbranch(branch_code);
									if (_.isEmpty(item)) {
										return (undefined)
									}
									const branch_name = makebranchname(item);
									const {baddress, saddress} = makebranchaddress(item);
									return (
										<Grid container direction={'column'} spacing={1} value={item.branch_code}>
											<Grid item>
												<Typography component={'p'} className={commonStyle.label}>
													BRANCH NAME
												</Typography>
												<Typography component={'p'} className={commonStyle.value}>
													{branch_name}
												</Typography>
											</Grid>
											<Grid item>
												<Typography component={'p'} className={commonStyle.label}>
													ADDRESS1
												</Typography>
												<Typography component={'p'} className={commonStyle.value}>
													{baddress}
												</Typography>
											</Grid>
											<Grid item>
												<Typography component={'p'} className={commonStyle.label}>
													ADDRESS2
												</Typography>
												<Typography component={'p'} className={commonStyle.value}>
													{saddress}
												</Typography>
											</Grid>
										</Grid>
									)
								}}
								onChange={onSelectBranch}>
								{
									branches.map((item, index) => {
										const {saddress} = makebranchaddress(item);
										const branch_name = makebranchname(item);
										return (
											<MenuItem value={item.branch_code} key={String(index)}>
												<Typography component={'div'} className={commonStyle.value}>
													{branch_name} | {saddress}
												</Typography>
											</MenuItem>
										)
									})
								}
							</Select>
						</FormControl> :
						null
				}

				<div className={commonStyle.wrapper}>
					<div className={commonStyle.inputWrapper}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								disablePast
								variant="inline"
								label={String(language.choose_date).toUpperCase()}
								value={chosenDateFrom}
								onChange={setChosenDateFrom}
							/>
						</MuiPickersUtilsProvider>
					</div>
					<div className={commonStyle.inputWrapper}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								disablePast
								variant="inline"
								label={String(language.due_date).toUpperCase()}
								value={chosenDueDateFrom}
								onChange={setChosenDueDateFrom}
								disabled={!asDueDate}
							/>
						</MuiPickersUtilsProvider>
					</div>
					<div className={commonStyle.radioWrapper}>
						<FormControlLabel
							control={
								<Switch
									color={'primary'}
									checked={asDueDate}
									onChange={onDueDate}
									name={language.set_due_date}/>}
							label={
								<Typography
									component={'p'}
									className={commonStyle.searchBtnFont}>
									{String(language.set_due_date).toUpperCase()}
								</Typography>
							}
						/>
					</div>
				</div>
				<div className={commonStyle.wrapper}>
					<div className={commonStyle.inputWrapper}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								disabled={!enableTime}
								variant="inline"
								label={language.time}
								value={chosenTime}
								onChange={setChosenTime}
							/>
						</MuiPickersUtilsProvider>
					</div>
					<div className={commonStyle.radioWrapper}>
						<FormControlLabel disabled={allDaySched}
							control={
								<Switch
									color={'primary'}
									checked={enableTime}
									onChange={onEnableTime}
									name={language.set_time}/>}
							label={<Typography
								component={'p'}
								className={commonStyle.searchBtnFont}>
								{String(language.set_time).toUpperCase()}
							</Typography>
							}
						/>
					</div>
				</div>
				<div className={commonStyle.wrapper}>
					<div className={commonStyle.inputWrapper}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<FormControl disabled={!enableDuration} className={commonStyle.inputWrapper}>
								<InputLabel id="duration-id-label">Duration</InputLabel>
								<Select
									labelId="duration-id-label"
									id="duration-id"
									value={chosenDuration}
									onChange={onSelectDuration}>
									{
										durationOptions.map((item, index) =>
											<MenuItem key={String(index)} value={item.value}>{item.name}</MenuItem>
										)
									}
								</Select>
							</FormControl>
							{/* <KeyboardTimePicker
                                disabled={!enableDuration}
                                ampm={false}
                                variant="inline"
                                label={language.duration}
                                value={chosenDuration}
                                onChange={setChosenDuration}
                            /> */}
						</MuiPickersUtilsProvider>
					</div>
					<div className={commonStyle.radioWrapper}>
						<FormControlLabel disabled={allDaySched}
							control={
								<Switch
									color={'primary'}
									checked={enableDuration}
									onChange={onEnableDuration}
									name={language.set_duration}/>}
							label={
								<Typography
									component={'p'}
									className={commonStyle.searchBtnFont}>
									{String(language.set_duration).toUpperCase()}
								</Typography>}
						/>
					</div>
				</div>
				<div className={commonStyle.wrapper}>
					<FormControl disabled={!repeatSched} className={commonStyle.inputWrapper}>
						<InputLabel id="interval-id-label">Interval</InputLabel>
						<Select
							labelId="interval-id-label"
							id="interval-id"
							value={chosenRepeatSched}
							onChange={onSelectRepeatSched}>
							{
								repeatOptions.map((item, index) =>
									<MenuItem key={String(index)} value={item.value}>{item.name}</MenuItem>
								)
							}
						</Select>
					</FormControl>
					<div className={commonStyle.radioWrapper}>
						<FormControlLabel
							control={
								<Switch
									color={'primary'}
									checked={repeatSched}
									onChange={onRepeatSched}
									name={language.repeat_sched}/>}
							label={
								<Typography
									component={'p'}
									className={commonStyle.searchBtnFont}>
									{String(language.repeat_sched).toUpperCase()}
								</Typography>}
						/>
					</div>
				</div>
				<div className={commonStyle.wrapper}>
					<FormControl className={commonStyle.inputWrapper}></FormControl>
					<div className={commonStyle.radioWrapper}>
						<FormControlLabel
							control={
								<Switch
									color={'primary'}
									checked={allDaySched}
									onChange={onAllDaySched}
									name={language.all_day}/>}
							label={
								<Typography
									component={'p'}
									className={commonStyle.searchBtnFont}>
									{String(language.all_day).toUpperCase()}
								</Typography>}
						/>
					</div>
				</div>
				<TextField
					onChange={e => setChosenNote(e.target.value)}
					className={commonStyle.textAreaWrapper}
					id="filled-basic"
					label={language.instructions}
					variant="filled" fullWidth/>

				<Button
					startIcon={isLoading ? <CircularProgress color={'inherit'} size={20}/> : undefined}
					disabled={disableSave}
					onClick={() => {
						onSave()
					}}
					variant={'contained'}
					fullWidth
					color={'primary'}
					className={commonStyle.button}>
					<Typography component={'p'} className={commonStyle.searchBtnFont}>
						{language.save}
					</Typography>
				</Button>
			</div>
			<VirtualList
				open={customerView}
				setClose={data => onCustomerViewClose(data)}
				title={language.cust_list}
				data={customerList}
				language={language}
			/>
			<VirtualListCB
				open={multiCustomerView}
				setClose={data => onMultiCustomerViewClose(data)}
				title={language.cust_list}
				data={filteredCsList}
				language={language}
				filteration={0}
			/>
			<CustomerFullTextSearch
				preset={multipleCustomers}
				open={filterCustomerView}
				setClose={(d) => {
					onCustomerFilterViewClose(d)
				}}
				title={language.fulltext_cust}
				language={language}
				axiosGet={axiosGet}
			/>
		</AnyDialog>
	)
}
