import React, {useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import {fade, lighten, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import red from '@material-ui/core/colors/red';
import JString from '@easytech-international-sdn-bhd/jstring';
import Checkbox from '@material-ui/core/Checkbox';
import { Menu, MenuItem } from '@material-ui/core';
import { de } from '../../Helper/Helper';

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: '1 1 100%',
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	viewInRow2: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'right'
	},
}));
const useSearchStyle = makeStyles((theme) => ({
	margin: {
		marginTop: theme.spacing(1),
		marginLeft: 0,
		paddingLeft: 0
	},
	root: {
		flexGrow: 1,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.10),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.15),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '35ch',
			'&:focus': {
				width: '60ch',
			},
		},
	},
}));

export default function VirtualListCB({setClose, open, title, data, language, limit, filteration}) {
	const [dataArr, setDataArr] = useState([]);
	const classes = useToolbarStyles();
	const searchClass = useSearchStyle();
	const [checkedList, setCheckedList] = useState({});
	const [listReturn, setListReturn] = useState([]);
	const [statusAnchor, setStatusAnchor] = useState(null);
	const [status, setStatus] = useState(1);
	useEffect(() => {
		de("betty-data-1", status);
		if (data && data.length > 0) {
			if (filteration) {
				de("betty-status", status, data.filter(item => item.status === status));
				setDataArr(dataArr => (data.filter(item => item.status === status)));
			} else {
				setDataArr(dataArr => (data));
			}
		}
	}, [open, data, status]);

	const onSelectChange = (_cur) => {
		const {name, value} = _cur;
		var tmp = Object.assign({}, checkedList);
		if (value in tmp) {
			delete tmp[`${value}`];
		} else {
			tmp[`${value}`] = _cur;
		}
		setCheckedList(tmp);
	};

	const checkAll = () => {
		var tmp = Object.assign({}, checkedList);
		for (var i = 0; i < dataArr.length; i++) {
			const {name, value, hide} = dataArr[i];
			if(hide == false || hide == undefined){
				if (value in tmp) {
					delete tmp[`${value}`];
				} else {
					tmp[`${value}`] = dataArr[i];
				}
			}
		}
		setCheckedList(tmp);
	};

	const savecustomer = () => {
		const list = Object.values(checkedList);
		var selectedData = [];

		for (var i = 0; i < list.length; i++) {
			selectedData.push(
				{
					name: list[i].name,
					value: list[i].value
				}
			);
		}
		if (filteration) {
			setDataArr(dataArr => (data.filter(item => item.status === status)));
		} else {
			setDataArr(data);
		}
		setListReturn(selectedData);
		setClose(selectedData);
	};

	const reset = () => {
		setDataArr([]);
		setCheckedList({});
		setListReturn([]);
		setStatus(1);
	};

	const RenderRow = ({props}) => {
		const {index, style} = props;
		const _cur = dataArr[index];
		const isChecked = ({value}) => {
			return value in checkedList;
		}
		if (index !== dataArr.length) {
			return (
				<ListItem style={style} key={index} className={searchClass.margin}>
					<Checkbox
						id={_cur?.value}
						disabled={_cur?.hide === true}
						onChange={(e) => _cur?.hide ? null : onSelectChange(_cur)}
						inputProps={{'aria-labelledby': {index}}}
						checked={isChecked(_cur)}
						color={isChecked(_cur) ? 'primary' : 'secondary'}
					/>
					<ListItemText
						primary={_cur?.name}
						secondary={_cur?.description != null ?
							<div>
								<div>{_cur?.description}</div>
								<div>{_cur?.value}</div>
							</div>
							: _cur?.secondary}
						secondaryTypographyProps={{
							style:{
								textDecorationLine: _cur?.hide ? "line-through" : undefined
							}
						}}
						primaryTypographyProps={{
							style:{
								textDecorationLine: _cur?.hide ? "line-through" : undefined
							}
						}}
					/>
				</ListItem>
			);
		} else {
			return (
				null
			);
		}
	}

	const onChangeSearchText = props => event => {
		var value = event.target.value;
		value = String(value).split('?').join('').split('[').join('').split(']').join('').split("(").join('').split(')').join('');
		var text = JString(value);
		var final = [];
		var search = (text.str).split(' ');
		if (text.isValid()) {
			for (let idx = 0; idx < data.length; idx++) {
				var count = 0;
				const row = data[idx];
				var searchString = row.value + row.name;
				for (let i = 0; i < search.length; i++) {
					var isFound = JString(searchString).searchDeep(search[i]);
					if ((isFound && filteration && row.status === status) || (isFound && !filteration)) {
						count++;
					}
				}
				if (count === search.length) {
					final.push(row);
				}
			}
			setDataArr(final);
		} else {
			if (filteration) {
				setDataArr(dataArr => (data.filter(item => item.status === status)));
			} else {
				setDataArr(data);
			}
		}
	}

	return (
		<React.Fragment>
			<Dialog
				fullScreen={false}
				maxWidth={'lg'}
				open={open}
				onClose={() => {
				}}
				aria-labelledby="max-width-dialog-title">
				<DialogTitle id="max-width-dialog-title">
					<div className={classes.viewInRow}>
						{title}
						{
							(typeof (limit) !== 'undefined' && limit > 1) && (
								<Typography component="p" color="inherit">Limit {limit} options.</Typography>
							)
						}
						{
							filteration ? (<>
								<Button
									style={{width: 100}}
									variant="outlined"
									aria-controls="active-status-menu"
									aria-haspopup="true"
									onClick={event=>setStatusAnchor(event.currentTarget)}>
									{status === 1 ? language.active : language.inactive}
								</Button>
								<Menu
									id="active-status-menu"
									anchorEl={statusAnchor}
									keepMounted
									open={Boolean(statusAnchor)}
									onClose={()=>setStatusAnchor(null)}
									>
									<MenuItem style={{width: 100}} 
									onClick={()=>{setStatusAnchor(null); setStatus(1);}}
									>Active</MenuItem>
									<MenuItem style={{width: 100}} 
									onClick={()=>{setStatusAnchor(null); setStatus(0);}}
									>{language.inactive}</MenuItem>
								</Menu>
							</>):<></>
						}
					</div>
					<div className={classes.viewInRow}>
						<div className={searchClass.search}>
							<div className={searchClass.searchIcon}>
								<SearchIcon/>
							</div>
							<InputBase
								placeholder={language.search}
								classes={{
									root: searchClass.inputRoot,
									input: searchClass.inputInput,
								}}
								inputProps={{'aria-label': 'search'}}
								onChange={onChangeSearchText('search')}
							/>
						</div>
						<Button onClick={() => checkAll()} size="small">
							<Typography component="p" color="inherit">
								{language.check_all}
							</Typography>
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<FixedSizeList
						height={400}
						width={600}
						itemSize={70}
						itemCount={dataArr ? dataArr.length : 0}>
						{
							children =>
								<RenderRow props={children}/>
						}
					</FixedSizeList>
				</DialogContent>
				<DialogActions>
					<Button
						color="inherit"
						onClick={() => {
							setClose({name: 'reset', value: ''});
							reset();
						}}
						size="small">
						<Typography component="p" style={{color: red[400]}}>
							{language.reset_selection}
						</Typography>
					</Button>
					<Button
						color="inherit"
						onClick={() => {
							setClose(false);
							reset();
						}}
						size="small">
						<Typography component="p" color="inherit">
							{language.cancel}
						</Typography>
					</Button>
					<Button
						disabled={(typeof (limit) !== 'undefined' && limit > 0) && (Object.keys(checkedList).length > limit)}
						color="inherit"
						onClick={() => {
							savecustomer();
							reset();
							setDataArr(data);
						}}
						size="small">
						<Typography component="p" color="inherit">
							{language.done}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
