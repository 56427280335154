import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
	ck_u_password,
	ck_u_username,
	defDateFormat,
	empty,
	req_cust,
	req_cust_area,
	req_module_setting,
    req_report_skureport,
    req_report_sku,
    req_salesman,
	req_mobile_module_setting,
	req_delete_file,
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import useCookieHook from "../../Services/useCookieHook";
// import GenerateView from "../../Components/GenerateView";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import { de, getSetting } from '../../Helper/Helper';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

export default function RSKU() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();
	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const typeOptions = [
		{name: 'Basic Version', value: 'simply'},
		{name: 'Detailed Version', value: 'normal'},
		{name: 'Category Version', value: 'category'}
	]

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	// const [categoryList, setCategoryList] = useState([]);
	// const [printView, setPrintView] = useState();

	const [dateRange, setDateRange] = useState({});
	const [openDateDoc, setDateDoc] = useState(false);
	const [spView, setSpView] = useState(false);
	const [reportOutletView, setReportOutletView] = useState(false);
	const [reportOutlet, setReportOutlet] = useState(empty);
	const [reportOutletSelect, setReportOutletSelected] = useState(empty);
	const [reportSpSelect, setReportSpSelected] = useState(empty);
	const [reportSalesperson, setReportSalesperson] = useState(empty);
	const [displayList, setDisplayList] = useState([]);
	const [tableHeader, setTableHeader] = useState([]);
	const [searchByArea, setSearchByArea] = useState(false);
	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

	const tableHead = [
		{
			name: "checkin_date",
			label: "Date",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return moment(value).format('DD/MM/YYYY HH:mmA');
				}
			}
		},
		{
			name: "customer_area",
			label: "Outlet Area",
			options: {
				display: true
			}
		},
		{
			name: "agent_name",
			label: "Agent",
			options: {
				display: true
			}
		},
		{
			name: "customer_name",
			label: "Outlet",
			options: {
				display: true
			}
		},
		{
			name: "company",
			label: "Branch",
			options: {
				display: true,
				customBodyRender: (value) => value ? value.toUpperCase() : ''
			}
		},
		{
			name: "client_sku",
			label: gState.client?.toUpperCase()+" SKU",
			options: {
				display: true
			}
		},
		{
			name: "total_sku",
			label: "Total SKU",
			options: {
				display: true
			}
		},
		{
			name: "client_sku_percent",
			label: "SKU %",
			options: {
				display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return value+"%";
				}
			}
		}
	];

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			// loadGalleryType(gState.client);
			loadConfig(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customization', {});
				if(_config.searchbyarea){
					setSearchByArea(_config.searchbyarea);
					getAreaList(_client);
				}
			}
		});
		API_Service.get(req_mobile_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const {module, pdf_images} = extraData;
				for (let m = 0; m < module.length; m++) {
				}
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, value} = extraData[idx];
					_data.push({name: name, secondary: value, value: value, status: 1});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getDisplayData = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: reportSpSelect,
			type: 'simple',
			username: cookies(ck_u_username),
			cust_code: reportOutletSelect,
			area: selectedArea
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_sku}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const {header, result} = extraData;
				for (let i = 0; i < header.length; i++) {
					var head = header[i];
					tableHead.splice(5, 0, {
						name: head['name'],
						label: head['label'],
						options: {
							display: true
						}
					});
				}
				setTableHeader(tableHead);
				setDisplayList(displayList => (result));
			} else {
				setDisplayList(displayList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			}
		});
	}

	const getOutletList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const ReportSPViewClick = () => {
		setSpView(true);
	}

	const ReportSPViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setReportSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setReportSalesperson(spname);
		}
	}

	const ReportOutletViewClick = () => {
		setReportOutletView(true);
	}

	const ReportOutletViewClose = (newValue) => {
		setReportOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setReportOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setReportOutlet(spname);
		}
	}

	const openPrintSelectView = () => {
		// setPrintView(true);
		generateReport(1);
	}

	// const printViewClose = (type) => {
	// 	generateReport(type);
	// 	setPrintView(false);
	// }

	const getReportDate = () => {
		return !_.isEmpty(dateRange) ? moment(dateRange.startDate).format('DD MMMM YYYY') + " / " + moment(dateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const generateReport = (type, _client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		const param = axiosURIencode({
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: reportSpSelect,
			type: 'simple',
			username: cookies(ck_u_username),
			cust_code: reportOutletSelect,
			area: selectedArea
		});
		API_Service.get(`${req_report_skureport}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				window.open(extraData.url, '_blank');
				const delay = setTimeout(() => {
					API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
					clearTimeout(delay);
				}, 5000);
			} else {
				snack(language.no_data, 'warning');
			}
		}).catch(err => {
			snack('The data is too large, please simplify the requirements', 'warning');
		});
	}

	return (
		<div>
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => ReportSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{reportSalesperson ? _.truncate(reportSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => ReportOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{reportOutlet ? _.truncate(reportOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				{
					searchByArea && (
						<Button
							style={{width: 300, marginRight: 10}}
							variant="outlined"
							onClick={() => AreaViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
							</Typography>
						</Button>
					)
				}
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getReportDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getDisplayData()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				displayList.length > 0 && (
					<MUIDataTable
						title={language.rdisplay}
						data={displayList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
										break;
									case 'remove':
										tableHeader[index].options.display = false;
										break;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={openPrintSelectView}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={spView}
				setClose={data => ReportSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={reportOutletView}
				setClose={data => ReportOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={0}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setDateRange(range);
					setDateDoc(false)
				}}
				setNegative={() => {
					setDateDoc(false)
				}}
				setClose={() => {
					setDateDoc(false)
				}}
				open={openDateDoc}
				message={language.date_range}
				positive={language.ok}
			/>

			{/* <GenerateView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={printView}
				onClose={() => setPrintView(false)}
				onSelect={printViewClose}
				value={'simply'}
				title={language.generate_type || ''}
				language={language}
				options={typeOptions}
				list={categoryList}
			/> */}
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
