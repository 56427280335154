import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    req_cust,
    req_cust_area,
    req_cust_category,
    req_cust_chain,
    req_module_setting,
    req_report_aaanalysis,
    req_salesman,
    req_report_aaanalysisreport,
    req_delete_file
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {de, getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Tooltip, IconButton, Menu, MenuItem, CircularProgress, TextField, InputAdornment } from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import { grey, orange, red } from '@material-ui/core/colors';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

const useStyles = makeStyles((theme) => ({
    viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	},
	container: {
        maxHeight: '78vh',
        maxWidth: '92vw',
    },
    boldText: {
        fontWeight: 'bold',
    },
    tableHeadRow: {
        backgroundColor: '#424242',
		fontSize: 10,
		padding: 0,
		margin: 0
    },
	tableCellAutoWidth: {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		fontSize: 12,
		padding: 0,
		margin: 0
	},
	table : {
		// borderCollapse: 'collapse',
		fontSize: 12,
		borderCollapse: 'separate',
    	borderSpacing: '4px 0px'
	},
	customDatePicker: {
		"& .MuiOutlinedInput-root" : {
			height: "100%",
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "5px 15px",
			fontSize: 12,
			textAlign: "center",
		},
		width: 300,
		marginRight: 10,
		fontSize: 12,
	},
}));

export default function ROutletVisitAnalysis() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	// const [nClient, setClient] = useState(gState.client);

    const date = [
		{name: 'January', value: 1},
		{name: 'Febuary', value: 2},
		{name: 'March', value: 3},
		{name: 'April', value: 4},
		{name: 'May', value: 5},
		{name: 'June', value: 6},
		{name: 'July', value: 7},
		{name: 'August', value: 8},
		{name: 'September', value: 9},
        {name: 'October', value: 10},
        {name: 'November', value: 11},
        {name: 'December', value: 12},
        {name: 'Reset', value: 'reset'}
	];

    const [outletVisitList, setOutletVisitList] = useState([]);
    const [totalList, setTotalList] = useState([]);
    const [total, setTotal] = useState(0);
	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
    const [outletCategoryList, setOutletCategoryList] = useState([]);
    const [outletChainList, setOutletChainList] = useState([]);

	const [outletVisitDateRange, setOutletVisitDateRange] = useState({});
	const [openOutletVisitDateDoc, setOpenOutletVisitDateDoc] = useState(false);

	const [spView, setSpView] = useState(false);
	const [spSelectedValue, setSpSelectedValue] = useState(empty);
	const [spSelectedName, setSpSelectedName] = useState(empty);

	const [outletView, setOutletView] = useState(false);
	const [outletSelectedName, setOutletSelectedName] = useState(empty);
	const [outletSelectedValue, setOutletSelectedValue] = useState(empty);

	const [searchByArea, setSearchByArea] = useState(false);
	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

    const [categoryListView, setCategoryListView] = useState(false);
    const [categorySelectedName, setCategorySelectedName] = useState(empty);
    const [categorySelectedValue, setCategorySelectedValue] = useState(empty);

    const [chainListView, setChainListView] = useState(false);
    const [chainSelectedName, setChainSelectedName] = useState(empty);
    const [chainSelectedValue, setChainSelectedValue] = useState(empty);
    
    const [selectedDate, setDate] = useState(empty);
    const [dateView, setDateView] = useState(null);

	const [limit, setLimit] = useState(2);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			getSettings(gState.client);
            getOutletChain(gState.client);
            getOutletCategory(gState.client);
            getOutletChain(gState.client);
		}
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const getOutletVisit = (_client = client) => {
		setLoading(true);
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		let formData = new FormData();
		formData.append('client', client);
		formData.append('date_from', st_date);
		formData.append('date_to', st_date_to);
		formData.append('salespersonId', spSelectedValue);
		formData.append('customer_id', outletSelectedValue);
		formData.append('category', categorySelectedValue);
		formData.append('chain', chainSelectedValue);
		formData.append('area', selectedArea);
		formData.append('username', cookies(ck_u_username));

		API_Service.post(req_report_aaanalysis, formData, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
                const {data, per_day, total} = extraData;
				setOutletVisitList(outletVisitList => (data));
                setTotalList(totalList => (per_day));
                setTotal(total);
			} else {
				setOutletVisitList(outletVisitList => ([]));
				snack(language.no_data, 'warning');
			}
			setLoading(false);
		});
	}

	const getSettings = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const _config = getSetting(extraData, 'customization', {});
				if(_config.searchbyarea){
					setSearchByArea(_config.searchbyarea);
					getAreaList(_client);
				}
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, value} = extraData[idx];
					_data.push({name: name, secondary: value, value: value});
				}
				setAreaList(_data);
			}
		});
	}

    const getOutletChain = (_client = client) => {
        API_Service.get(req_cust_chain, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;

				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_chain_name, cust_chain_code, cust_chain_id, cust_chain_status} = extraData[i];
						if (parseInt(cust_chain_status) === 1) {
							_data.push({
                                name: cust_chain_name,
                                secondary: cust_chain_code,
                                value: cust_chain_id,
                                status: 1
                            });
						} else {
							_data.push({
                                name: cust_chain_name,
                                secondary: cust_chain_code,
                                value: cust_chain_id,
                                status: 0
                            });
						}
					}
					setOutletChainList(()=>(_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
    }

    const getOutletCategory = (_client = client) => {
        API_Service.get(req_cust_category, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;

				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_category_name, cust_category_code, cust_category_id, cust_category_status} = extraData[i];
						if (parseInt(cust_category_status) === 1) {
							_data.push({
                                name: cust_category_name,
                                secondary: cust_category_code,
                                value: cust_category_id,
                                status: 1
                            });
						} else {
							_data.push({
                                name: cust_category_name,
                                secondary: cust_category_code,
                                value: cust_category_id,
                                status: 0
                            });
						}
					}
					setOutletCategoryList(()=>(_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
    }

	const getSalesmanList = (_client = client) => {
		API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = client) => {
		API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
                        status: 1
					});
				}
                for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
						status: 0
                    });
                }
				setOutletList(arr);
			}
		});
	}

	const OutletVisitSPViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setSpSelectedValue(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setSpSelectedName(spname);
		}
	}

	const OutletVisitOutletViewClose = (newValue) => {
		setOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletSelectedValue(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletSelectedName(custname);
		}
	}

    const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

    const CategoryViewClose = (newValue) => {
        setCategoryListView(false);
        if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setCategorySelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setCategorySelectedName(name);
		}
    }

    const ChainViewClose = (newValue) => {
        setChainListView(false);
        if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setChainSelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setChainSelectedName(name);
		}
    }

	const getOutletVisitDate = () => {
		return !_.isEmpty(outletVisitDateRange) ? moment(outletVisitDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(outletVisitDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const onGenerateReport = (_client = client) => {
		setLoading(true);
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		let formData = new FormData();
		formData.append('client', client);
		formData.append('date_from', st_date);
		formData.append('date_to', st_date_to);
		formData.append('salespersonId', spSelectedValue);
		formData.append('customer_id', outletSelectedValue);
		formData.append('category', categorySelectedValue);
		formData.append('chain', chainSelectedValue);
		formData.append('area', selectedArea);
		formData.append('username', cookies(ck_u_username));

		API_Service.post(req_report_aaanalysisreport, formData, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
                window.open(extraData.url, '_blank');
                const delay = setTimeout(() => {
                    API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
                    clearTimeout(delay);
                }, 5000);
            } else {
                snack(language.no_data, 'warning');
            }
			setLoading(false);
        });
	}

    const DateOpen = (event) => {
		setDateView(event.currentTarget);
	};

    const SelectionDateClose = (data) => {
		setDateView(null);
	};

    const SelectionDateClick = (each) => {
        const year = new Date().getFullYear();
		if(each.value === 'reset'){
			setDate(empty);
            setOutletVisitDateRange({});
		}else{
			setDate(each);
            setOutletVisitDateRange({
                startDate: new Date(year, each.value - 1, 1),
                endDate: new Date(year, each.value, 0)
            });
		}
		setDateView(null);
	};


	return (
		<div>
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setSpView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{spSelectedName ? _.truncate(spSelectedName, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOutletView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletSelectedName ? _.truncate(outletSelectedName, {length: 20}) : language.customer}
					</Typography>
				</Button>
				{
					searchByArea && (
						<Button
							style={{width: 300, marginRight: 10}}
							variant="outlined"
							onClick={() => setAreaView(true)}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
							</Typography>
						</Button>
					)
				}
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCategoryListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{categorySelectedName ? _.truncate(categorySelectedName, {length: 20}) : language.category}
					</Typography>
				</Button>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setChainListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{chainSelectedName ? _.truncate(chainSelectedName, {length: 20}) : language.chain}
					</Typography>
				</Button>
                {/* <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={DateOpen}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedDate ? _.truncate(selectedDate.name, {length: 20}) : language.date}
					</Typography>
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={dateView}
					keepMounted
					open={Boolean(dateView)}
					onClose={SelectionDateClose}
				>
					{
						date.map((each) => {
							if(each.value === 'reset'){
								return (<MenuItem style={{ color: red[400] }} key={each.value} onClick={() => SelectionDateClick(each)}>{each.name}</MenuItem>);
							}else{
								return (<MenuItem key={each.value} onClick={() => SelectionDateClick(each)}>{each.name}</MenuItem>);
							}
						})
					}
				</Menu> */}
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						className={classes.customDatePicker}
						inputVariant={"outlined"}
						// label={'"Month" and "Year"'}
						views={['month', 'year']}
						// format="yyyy-MM-dd"
						value={outletVisitDateRange.startDate}
						onChange={(a) => {
							setOutletVisitDateRange({
								startDate: new Date(a.getFullYear(), a.getMonth(), 1),
								endDate: new Date(a.getFullYear(), a.getMonth() + 1, 0)
							});
						}}
						renderInput={(params) => (
							<TextField {...params} InputProps={{
              					endAdornment: 
									<InputAdornment position="end">
										<InsertInvitationIcon
											//   color="action"
											//   style={{ cursor: 'pointer' }}
										/>
									</InputAdornment>
            				}} />
						)}
					/>
				</MuiPickersUtilsProvider>
				{/* <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenOutletVisitDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getOutletVisitDate()}
					</Typography>
				</Button> */}
				<Button
					style={{marginRight: 10}}
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getOutletVisit()}
					disabled={loading ? true : false}>
					<Typography className={commonStyle.searchBtnFont}>{language.view}</Typography>
					{
						loading ? <CircularProgress size={20}/> : ''
					}
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => onGenerateReport()}
					disabled={loading ? true : false}>
					<Typography className={commonStyle.searchBtnFont}>Generate Report</Typography>
					{
						loading ? <CircularProgress size={20}/> : ''
					}
				</Button>
			</div>
			<div style={{height: 20}}/>
            {
                outletVisitList.length > 0 && (
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader cellSpacing="0" cellPadding="0" sx={{ minWidth: 650 }} size="small" className={classes.table} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadRow} align="left" colSpan={Object.keys(totalList).length + 10}><Typography component="h6" className={classes.boldText}>Outlet Visited Analysis</Typography></TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left" colSpan={2}><React.Fragment>
                                        {/* <Tooltip title={'Generate Report'}>
                                            <IconButton
                                                edge={'end'}
                                                color={'primary'}
                                                onClick={() => onGenerateReport()} aria-label="addnew">
                                                <AssessmentIcon/>
                                            </IconButton>
                                        </Tooltip> */}
                                    </React.Fragment></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHeadRow} align="left">No</TableCell>
                                    <TableCell className={classes.tableHeadRow} colSpan={4} align="left" style={{width:100}}>Outlet</TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left">Agent</TableCell>
                                    {Object.keys(totalList).map((drow, dindex) => {
										const test = moment(drow).format('DD/MM/YYYY');
										const parts = String(drow).split("/");
										const currentYear = new Date().getFullYear();
										const formattedDate = new Date(currentYear, parseInt(parts[1]) - 1, parseInt(parts[0]));
                                        return (
											<TableCell 
											key={`title${dindex}`} 
											className={classes.tableHeadRow} 
											style={{backgroundColor: formattedDate.getDay() > 0 ? '' : grey['A400']}} 
											align="left">
												{parts[0]}
											</TableCell>
										)
									})}
                                    <TableCell className={classes.tableHeadRow} align="left">Total</TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left">Last Checked</TableCell>
									<TableCell className={classes.tableHeadRow} align="left">Category</TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left">Chain</TableCell>
									<TableCell className={classes.tableHeadRow} align="left">PersonMet</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    outletVisitList.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} align="left">{index + 1}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} colSpan={4} align="left">{row.outlet}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} align="left">{row.agent}</TableCell>
                                            {Object.keys(row.data).map((drow, dindex) => {
												const parts = String(drow).split("/");
												const currentYear = new Date().getFullYear();
												const formattedDate = new Date(currentYear, parseInt(parts[1]) - 1, parseInt(parts[0]));
												if (row.data[drow] === 0) {
													return (<TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: formattedDate.getDay() > 0 ? '' : grey['A400']}} key={`data${index}${dindex}`} align="left"></TableCell>);
												} else {
													return (<TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: formattedDate.getDay() > 0 ? '' : grey['A400']}} key={`data${index}${dindex}`} align="left">{row.data[drow]}</TableCell>);
												}
											})}
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} align="left">{row.total > 0 ? row.total : ''}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} align="left">{row.total > 0 || row.last_checkin_date === '' ? '' : moment(row.last_checkin_date).format('DD/MM/YYYY')}</TableCell>
											<TableCell className={classes.tableCellAutoWidth} align="left">{row.category}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} align="left">{row.chain}</TableCell>
											<TableCell className={classes.tableCellAutoWidth} align="left">{row?.person_met ? row.person_met : ''}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow style={{ flexShrink: 0, background: '#424242', padding: '10px', position: 'sticky', bottom: 0, zIndex: 1000 }}>
                                    <TableCell rowSpan={1} />
                                    <TableCell className={classes.tableCellAutoWidth} colSpan={5}>Total Checkin Per Day</TableCell>
                                    {Object.keys(totalList).map((drow, dindex) => (
                                        <TableCell className={classes.tableCellAutoWidth} key={`footer${dindex}`} align="left">{totalList[drow]}</TableCell>
                                    ))}
                                    <TableCell className={classes.tableCellAutoWidth} align="left">{total}</TableCell>
                                    <TableCell align="left" colSpan={4} />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

			<VirtualListCB
				open={spView}
				setClose={data => OutletVisitSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				// limit={limit}
			/>

			<VirtualListCB
				open={outletView}
				setClose={data => OutletVisitOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
                filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
			/>

            <VirtualListCB
				open={categoryListView}
				setClose={data => CategoryViewClose(data)}
				title={"Outlet Category"}
				data={outletCategoryList}
				language={language}
                filteration={1}
			/>

            <VirtualListCB
				open={chainListView}
				setClose={data => ChainViewClose(data)}
				title={"Outlet Chain"}
				data={outletChainList}
				language={language}
                filteration={1}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setOutletVisitDateRange(range);
					setOpenOutletVisitDateDoc(false)
				}}
				setNegative={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				setClose={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				open={openOutletVisitDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
		</div>
	)
}
